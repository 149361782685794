@import "../../../styles/helpers";

.list {
  margin: (-32px) -16px 0;

  display: flex;
  flex-wrap: wrap;

  @include x {
    margin: (-32px) -10px 0;
  }

  @include d {
    margin: (-32px) -16px 0;
  }

  @include m {
    margin: 0;

    display: block;
  }
}

.card {
  margin: 32px 16px 0;

  width: calc(33.333% - 32px);

  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);
  background-color: unset;
  border: none;
  flex: 0 0 calc(33.333% - 32px);

  @include x {
    margin: 32px 10px 0;

    width: calc(33.333% - 20px);
    flex: 0 0 calc(33.333% - 20px);
  }

  @include d {
    margin: 32px 16px 0;

    width: calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
  }

  @include m {
    margin: 0;

    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.noItemsFound {
  margin-top: 69px;

  text-align: center;
}

.loader {
  margin: 32px auto 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    fill: $neutrals4;
  }
}

.text {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem auto;
  margin-bottom: 24px;
  color: $neutrals3;
  @include body-14;

  @include dark {
    color: $neutrals5;
  }
}

.text {
  margin: 20px auto;
  margin-bottom: 24px;

  color: $neutrals3;
  @include body-14;

  @include dark {
    color: $neutrals5;
  }
}

.textActiveLink {
  color: $orange-kephi;
}
