[class^="section"] {
  padding: 4.5rem 0;
}

.section {
  & + &-bg,
  &-bg + & {
    padding: 4.5rem 0;
  }

  &-dark {
    background: $grey-bg;
    @include dark-common {
      background: $dark;
    }
  }

  &-bg {
    background: $light;

    @include dark-common {
      background: lighten($dark, 5);
    }
  }

  &-pb {
    padding-top: 0;

    @include d {
      padding-top: 0;
    }

    @include m {
      padding-top: 0;
    }

    @include dark-common {
      background: $dark;
    }
  }

  &-pt80 {
    padding-top: 80px;

    @include d {
      padding-top: 64px;
    }

    @include m {
      padding-top: 32px;
    }
  }

  &-pt170 {
    padding-top: 170px;

    @include d {
      padding-top: 130px;
    }

    @include m {
      padding-top: 100px;
    }
  }
}