@import "../../../styles/helpers";

.options {
  display: flex;
  flex-direction: column;

  border-radius: 32px;
  gap: 16px;

  @include r("1024") {
    padding: 8px;

    flex-direction: row;

    background: $neutrals2;
    gap: 0;
  }

  .favorite {
    svg {
      fill: $pink;
    }

    &:hover {
      svg {
        fill: $pink !important;
      }
    }
  }
}

.button {
  &:not(:last-child) {
    margin-right: 10px;
  }
}
