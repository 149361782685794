@import "../../../../../styles/helpers";

@mixin button-active-grey {
  color: $neutrals2;

  &:hover {
    color: $white;
  }
}

$default-margin: 2rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  @include t {
    display: block;
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;

  display: flex;
  align-items: center;
  color: $light;
  margin-bottom: .75rem;
}

.subtitle {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  color: $light-fifty;
}

.item {
  position: relative;
  width: 100%;
}

.categoryContainer {
  min-height: 7.75rem;

  .errorContainer {
    clear: both;
    color: $dark;
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 0;
    min-height: 0;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }
  .errorLabel {
    font-size: .85rem;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: -.25rem;

    color: $red-dark;
  }
}

.note {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.file {
  position: relative;

  margin-top: 16px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 19.313rem;

  background: $light;
  border-radius: 16px;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }

  @include dark {
    background: $dark-light;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.icon {
  margin-bottom: 10px;

  width: 24px;
  height: 24px;

  svg {
    fill: $neutrals4;
  }
}

.format {
  @include caption-2;
  color: $neutrals4;
}

.category {
  @include body-bold-2;
  color: $neutrals2;
  @include dark {
    color: $neutrals6;
  }
}

.previewImg {
  img,
  video {
    width: 100%;
  }
}

.clear {
  position: absolute;
  top: 14px;
  right: 14px;

  cursor: pointer;
}
.label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: .75rem;

  color: $neutrals5;
  @include dark {
    color: $light;
  }
}
.filtersContainer {
  gap: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0;
}
.buttonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.375rem;
  width: 100%;
}
.backButton {
  font-weight: 700 !important;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
}
.nextbutton {
  font-weight: 700;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
  background: $gradient-gallery;
}

.advanceHeader {
  display: flex;
  padding: 0 0 .5rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75rem;
  width: 100%;

  cursor: pointer;
  svg {
    fill: $light-fifty;

    transition: transform .5s;
  }

  &.active {
    svg {
      transform: rotate(180deg);
      fill: $light;
    }
  }
}
.headerLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .25rem;

  font-size: 1rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;

  color: $neutrals5;
  @include dark {
    color: $light;
  }

  .optional {
    justify-content: start;
    text-align: start;
    text-align: left;
    font-weight: 500;
  }
}

.advanceForm {
  transition: .25s;
  height: 0;
  visibility: collapse;
  z-index: 1;

  &.visible {
    visibility: visible;
    transform-origin: top center;
    animation: slideDown .25s ease-in-out forwards;
  }

  &.hidden {
    animation: slideUp .5s ease-in forwards;
    transform-origin: top center;
    height: auto;
  }
}

@keyframes slideDown {
  0% {
    height: 0;
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    height: auto;
    opacity: .5;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-80px);
    height: 0;
  }
}