@import '../colors.scss';
@import '../helpers.scss';

[class^="button"] {
  padding: 0 24px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 48px;

  text-align: center;

  color: $light;
  background: $orange-kephi;
  border-radius: 24px;

  transition: all $animation-timing-default;

  @include button-1;

  &:hover {
    background: darken($orange-kephi, 10);
  }

  &.done {
    color: $neutrals3;
    background: $lightgray;
  }

  &.error {
    color: $pink;
    background: none;
    box-shadow: 0 0 0 2px $pink inset;

    @include dark-common {
      box-shadow: 0 0 0 2px $neutrals4 inset;
    }
  }

  svg {
    fill: $light;
  }

  &:not([class^="button-circle"]) {
    svg {
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

.button-stroke {
  color: $neutrals3;
  background: none;
  box-shadow: 0 0 0 2px $neutrals6 inset;

  svg {
    transition: fill $animation-timing-default;
    fill: $neutrals2;
  }

  &:hover {
    color: $light;
    background: darken($orange-kephi, 10);
    box-shadow: 0 0 0 2px $light inset !important;

    svg {
      fill: $light;
    }
  }

  @include dark-common {
    color: $light;
    box-shadow: 0 0 0 2px $neutrals4 inset;

    svg {
      fill: $light;
    }

    &:hover {
      box-shadow: 0 0 0 2px $neutrals5 inset;
    }
  }
}

.button-pink {
  background: $pink;

  &:hover {
    background: darken($pink, 12);
  }
}

.button-small {
  padding: 0 16px;

  height: 2.5rem;

  font-size: 14px;

  border-radius: 20px;
}

.button-circle-stroke {
  padding: 0;

  width: 48px;
  height: 48px;

  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $neutrals6 inset;

  transition: all $animation-timing-default;
  flex: 0 0 48px;

  svg {
    transition: fill $animation-timing-default;
    fill: $neutrals4;
  }

  &:hover {
    background: darken($orange-kephi, 10);
    // box-shadow: unset;
    box-shadow: 0 0 0 1px $orange-kephi inset;

    svg {
      fill: $light;
    }
  }

  @include dark-common {
    background: transparent;
    box-shadow: 0 0 0 1px $neutrals3 inset;

    &:hover {
      // background: $neutrals3;
      box-shadow: 0 0 0 1px $neutrals4 inset;
      svg {
        fill: $neutrals6;
      }
    }
  }

  &.button-small {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
}

.button {
  &-disabled,
  &-loading {
    position: relative;

    cursor: no-drop !important;

    background: #ffa96f;

    svg {
      display: none;
    }
  }

  &-loading {
    padding-right: 40px !important;
  }
}

.button-outlined {
  box-sizing: border-box;
  font-size: .875rem;
  font-weight: 400;
  transition: .25s;
  text-align: center;
  padding: .5rem 1.5rem;
  border-radius: 5.625rem;

  background-color: transparent;
  border: 2px solid $light-fifty;
  color: $light;

  img {
    // transition: .15s;
    filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
  }

  &:hover {
    font-weight: 700;
    background: transparent;
    border-color: $light;
    img {
      transition: .15s;
      filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
    }
  }

  &.active {
    font-weight: 700;
  }
}

.button-outlined-gradient {
  box-sizing: border-box;
  font-size: .875rem;
  font-weight: 400;
  transition: .25s;
  text-align: center;
  padding: .5rem 1.5rem;
  border-radius: 5.625rem;

  border: 2px solid $light-fifty;
  color: $light;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);

  &:hover, &.active {
    font-weight: 700;
    border-color: transparent;
    background-color: transparent;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 5.625rem;
      padding: 2px;
      margin: -2px;
      background: $gradient-gallery;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }
  }
}

.button-gradient {
  box-sizing: border-box;
  font-size: .875rem;
  font-weight: 700;
  transition: .25s;
  text-align: center;
  padding: .5rem 1.5rem;
  border-radius: 5.625rem;

  color: $light;
  background: $gradient-gallery;

  &:hover, &.active {
    font-weight: 900;
    background: $gradient-gallery;
    opacity: .95;
  }
}

.loader-btn {
  position: absolute;
  right: 8px;

  transform: translateY(-50%);
}
