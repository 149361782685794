@import "../../../styles/helpers.scss";

.skeletonResult {
  display: flex;
  flex-direction: column;
  padding: .125rem .125rem 0;
  align-items: flex-start;
}

.skeletonCategory {
  margin: 0 .75rem;
  border-radius: 5rem !important;
}