@import "../../../styles/helpers";

.previewSkeleton {
  min-width: 500px;
  width: 100% !important;
  min-height: 600px;
  border-radius: 16px !important;

  @include m {
    min-width: 100%;
    min-height: 400px;
  }
  @include a {
    min-width: 100%;
    min-height: 350px;
  }
}

.title {
  margin-top: .5rem;
}

.prices{
  display: flex;
  align-items: center;

  :not(:last-child) {
    padding-right: .25rem;
  }

  :last-child {
    padding-top: .25rem;
  }
}
.description {
  margin: 1.75rem 0;
  width: 90%;
}

.navItem {
  vertical-align: middle !important;
  width: 4rem !important;
  height: 1.5rem;
  margin: .125rem .25rem;
  border-radius: 10px !important;
}

.optionsMobile {
  display: flex;
  justify-content: center;
  margin-top: .5rem;
  @include rmin("1024") {
    visibility: hidden;
  }
}
.options {
  border-radius: 1.5rem !important;
}