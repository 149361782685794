@import "../../../styles/helpers";

.item {
  position: relative;

  padding: 16px 64px 16px 16px;

  display: flex;
  align-items: center;

  cursor: pointer;

  background: $light;
  border: 1px solid $lightgray;
  @include dark {
    background: $neutrals2;
    border: 1px solid $neutrals3;
  }

  border-radius: 20px;

  transition: background 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include m {
    padding-right: 32px;
  }

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 50%;
    right: 24px;

    width: 12px;
    height: 12px;

    transition: all 0.2s;
    transform: translateY(-50%);

    @include m {
      right: 12px;
    }
  }

  &::before {
    background: $orange-kephi;
    border-radius: 50%;
  }

  &::after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 6px auto;
    opacity: 0;
  }

  &:hover {
    background: $light;

    @include dark {
      background: $neutrals3;
    }

    &::before {
      transform: translateY(-50%) scale(0);
    }

    &::after {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.itemNonread {
  position: relative;

  padding: 16px 64px 16px 16px;

  display: flex;
  align-items: center;

  cursor: pointer;

  background: $light;
  border: 1px solid $lightgray;
  @include dark {
    background: $neutrals2;
    border: 1px solid $neutrals3;
  }
  border-radius: 20px;

  transition: background 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include m {
    padding-right: 32px;
  }

  &::after {
    content: "";

    position: absolute;
    top: 50%;
    right: 24px;

    width: 12px;
    height: 12px;

    transition: all 0.2s;
    transform: translateY(-50%);

    @include m {
      right: 12px;
    }
  }

  &::before {
    background: $orange-kephi;
    border-radius: 50%;
  }

  &::after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 6px auto;
    opacity: 0;
  }

  &:hover {
    background: $light;

    @include dark {
      background: $neutrals3;
    }

    &::before {
      transform: translateY(-50%) scale(0);
    }

    &::after {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.preview {
  position: relative;

  margin-right: 24px !important;

  width: 96px !important;
  height: 96px !important;
  flex-shrink: 0;

  @include m {
    margin-right: 16px !important;

    width: 64px !important;
    height: 64px !important;
  }

  & > img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 50%;

  @include m {
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: $light;
    }
  }
}

.details {
  flex-grow: 1;
}

.subtitle {
  @include body-bold-1;
  color: $dark;

  @include m {
    margin-bottom: 3px;

    font-size: 16px;
  }

  @include dark {
    color: $light;
  }
}

.description {
  @include body-2;
  color: $neutrals3;

  @include m {
    font-size: 12px;
  }

  @include dark {
    color: $neutrals6;
  }
}

.date {
  @include caption-bold-2;
  color: $neutrals4;
}
