@import "../../styles/helpers";

.section {
  overflow: hidden;

  @include m {
    padding: 1.5rem 0 0 !important;
    margin: 0;
  }
}

.preview {

  @include t {
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
  }
}

.wrap {
  margin: 0 auto;
  text-align: center;
}

.title {
  padding: 0 8rem;
  font-size: 1.375rem;
  font-weight: 550;
  line-height: 1.625rem;
  color: $neutrals3;
  @include dark {
    color: $neutrals5;
  }

  @include t {
    padding: 0 8rem;
    font-size: 1.25rem;
  }
  @include m {
    padding: 0 7rem;
    font-size: 1.125rem;
  }
  @include a {
    padding: 0 3rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  @include s {
    font-size: 1rem;
    padding: 0 1rem;
  }

}