@import "../../../../styles/helpers";

.iconWrapper {
  margin: 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon:hover {
    cursor: pointer;
  }
}

.title {
  margin-bottom: 24px;
}

.header {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;

  color: $neutrals5;
}

// .wrapper {
//   text-align: left;

//   @include body-bold-2;

//   input {
//     background: transparent;
//     color: $light !important;

//     &::placeholder {
//       color: $purple2;
//     }
//   }
// }
.btns {
  display: flex;
  justify-content: center;
}

.button {
  margin: 20px auto 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.suffix {
  padding: 0 6px;

  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;

  color: $orange-kephi;
  border: 2px solid $orange-kephi;
  border-radius: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  top: 9px;
  @include dark {
    color: $orange-kephi;
    border: 2px solid $orange-kephi;
  }
}

.field {
  margin-top: 12px;
  min-height: auto !important;

  input::placeholder {
    @include caption-bold-1;
    color: $neutrals4;
  }

  textarea,
  input {
    color: $neutrals2;
    border-color: $neutrals6;

    &:focus {
      border-color: $neutrals4;
    }
  }
}
