@import "../../styles/helpers.scss";

.note {
  display: inline-flex;
  font-size: .7rem;
  line-height: 1rem;
  color: $red-light;

  .crossIcon {
    padding-right: 0.25rem;
  }
}