@import "../../styles/helpers";

.itemContainer {
  position: relative;

  display: flex;
  align-items: flex-start;

  @include t {
    display: block;
  }

  @include m {
    padding: 0 30px !important;
  }
}

.bg {
  position: relative;
  flex-grow: 6;
  width: 55%;

  margin-right: 60px;

  @include x {
    margin-right: 40px;
  }

  @include d {
    margin-right: 32px;
  }

  @include t {
    width: 100%;
    margin: 0 0 2rem;
  }
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  width: 45%;

  @include t {
    width: 100%;
  }
}

.preview {
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: auto;
  height: auto;

  border-radius: 16px;
  flex-grow: 1;

  img,
  video {
    max-height: 620px;
    border-radius: 16px;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  video {
    max-width: 100%;
  }
}

.imageWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: fit-content;

  audio {
    margin-top: .75rem;
    width: 100%;
  }
}

.categories {
  position: absolute;
  top: 24px;
  left: 24px;

  margin: (-8px) 0 0 -8px;

  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}

.category {
  & + & {
    margin-left: 8px;
  }
}

.options {
  position: absolute;
  top: 0;
  right: -24px;

  transform: translateX(-50%);

  @include r("1024") {
    visibility: hidden;
    display: none !important;
  }

  & button {
    background-color: $white;
  }

  // & > :first-child {
  //   background: $neutrals2;
  //   box-shadow: unset;

  //   svg {
  //     fill: $neutrals4;
  //   }
  // }
}

.optionsMobile {
  position: relative;
  top: .5rem;
  text-align: center;
  justify-content: center;
  align-items: center;

  flex-direction: row;

  background-color: $light;

  @include rmin("1024") {
    visibility: hidden;
    display: none !important;
  }
}

.title {
  margin-bottom: 8px;

  word-wrap: break-word;
  width: auto;
  color: $neutrals2;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.125;
  padding-bottom: 1.5rem;

  @include a {
    font-size: 35px !important;
  }

  @include s {
    font-size: 30px !important;
  }
  @include dark {
    color: $light;
  }
}

.cost {
  margin-bottom: 1.5rem;

  display: flex;
  align-items: center;

  &Wrapper {
    display: flex;
  }

  &Container {
    & + & {
      margin-top: 10px;
    }
  }
  @include a {
    margin-bottom: 1.25rem;
  }
}

.price {
  @include SairaRegular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.563rem;
  padding: .5rem 1rem;
  margin-right: 8px;

  color: $light-fifty;
  border-radius: .625rem;
  box-shadow: inset 0 0 0 2px $neutrals5;

  // Posta
  // &:first-child {
  // Posta

  // Deshacer
  &:nth-child(2) {
  // deshacer
    color: $orange-kephi;
    box-shadow: inset 0 0 0 2px $orange-kephi;
    @include dark {
      color: ligthen($orange-kephi, 5);
      box-shadow: inset 0 0 0 2px ligthen($orange-kephi, 5);
    }
  }
  @include a {
    font-size: .875rem;
    line-height: 1rem;
  }
}

.counter {
  @include SairaRegular;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: $light-fifty;
  @include a {
    font-size: .875rem;
    line-height: 1rem;
  }
}

.info {
  margin-bottom: 1.5rem;
  max-width: fit-content;
  @include SairaRegular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.563rem;
  color: $light-fifty;

  @include t {
    max-width: 100%;
  }
  @include a {
    margin-bottom: 1.25rem;
    font-size: .875rem;
  }

  a {
    color: $orange-kephi;

    @include dark {
      color: $light;
    }
  }

  &.infoLite {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.navWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include s {
    overflow: scroll;

    @media only screen and (max-width: 420px) {
      justify-content: flex-start;
    }
  }
}

.nav {
  margin: .5rem 0;
  padding: .25rem;
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: $white;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutrals6;

  @include dark {
    box-shadow: inset 0 0 0 1px $light-twenty-five;
    background-color: transparent;
  }

  &:not(:last-child) {
    @include d {
      margin-right: auto;
    }

    @include t {
      margin-right: .125rem;
    }

    @include s {
      margin-right: auto;
    }
  }

  @include s {
    width: auto;
    // padding: .25rem .125rem;
  }
}

.control, .pendingStatus {
  margin-top: 20px;

  @include m {
    margin-top: 32px;
  }
}

.unlock {
  padding: 0 24px;
  margin: 15px 10px 0 10px;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 48px;

  text-align: center;

  cursor: pointer;

  color: $light;
  background: $orange-kephi;
  border-radius: 24px;
  gap: 24px;

  @include button-1;
  // transition: all $animation-timing-default ease;
  &:hover {
    background: darken($orange-kephi, 10);
  }

  &Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    gap: 12px;
  }

  &.opened {
    padding: 20px 24px;

    box-sizing: border-box;
    height: 100%;

    cursor: default;

    color: $neutrals4;
    background: $light;

    border: 1px solid $lightgray;
    border-radius: 16px;

    @include dark {
      background: $neutrals2;
      border-color: $neutrals3;
    }

    .unlockButton {
      padding: 5px 24px;

      cursor: pointer;

      color: $neutrals2;
      svg {
        path {
          &:first-of-type {
            fill: $neutrals2;
          }
          &:last-child {
            stroke: $neutrals2;
          }
        }
      }
      background: none;
      border-radius: 24px;
      box-shadow: 0 0 0 2px $neutrals6 inset;

      @include dark {
        color: $neutrals5;
        box-shadow: 0 0 0 2px $neutrals5 inset;

        svg {
          path {
            &:first-of-type {
              fill: $neutrals5;
            }
            &:last-child {
              stroke: $neutrals5;
            }
          }
        }
      }

      &:hover {
        color: $light;
        background: $orange-kephi;
        box-shadow: none !important;

        svg {
          path {
            &:first-of-type {
              fill: $light;

              &:last-of-type {
                fill: unset;
                stroke: $light;
              }
            }

            &:last-child {
              stroke: $light;
            }
          }
        }
      }
    }
  }
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;

    transition: fill $animation-timing-default;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.tooltip {
  position: relative;

  display: inline-block;
  visibility: hidden;

  border-bottom: 1px solid black;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;

  padding: 5px;

  visibility: hidden;

  min-width: 120px;

  text-align: center;

  color: #fff;
  background-color: black;
  border-radius: 6px;
}

.copy:hover .tooltiptext {
  visibility: visible;
}

.loaderContainer{
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  width: 100%;
}
.loader {
  margin: 0 auto;
  flex-grow: 1;
  width: 2rem;
  height: 2rem;

  svg {
    width: 100%;
    height: 100%;
    fill: $neutrals4;
  }
}

.zoomContainer {
  dialog {
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    > div {
      background: $neutrals6;
      @include dark {
        background: $neutrals2;
      }
      > img {
        height: 100vh;
        width: 100vw;
        max-height: 100vh;
        max-width: 100vw;
      }
    }
  }
}