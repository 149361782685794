@import "../../styles/helpers";

.section {
  overflow: hidden;

  @include m {
    padding-top: 48px;
  }
}

.preview {
  margin-bottom: 96px;

  @include d {
    margin: 0 -64px 90px;
  }

  @include t {
    margin-bottom: 64px;
  }

  @include m {
    margin: 0 -160px 32px;
  }

  img {
    width: 100%;
  }
}

.wrap {
  margin: 0 auto 96px;

  max-width: 736px;

  text-align: center;

  @include m {
    margin-bottom: 64px;
  }
}

.title {
  margin-bottom: 8px;
}

.info {
  margin-bottom: 24px;

  color: $neutrals4;
}

.form {
  margin: 0 auto;

  max-width: 300px;

  @include m {
    max-width: 100%;
  }
}

.subtitle {
  margin-bottom: 48px;

  text-align: center;

  @include body-bold-1;
}

.list {
  margin: 0 -16px;

  display: flex;

  @include d {
    margin: 0 -80px;

    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before,
    &::after {
      content: "";

      width: 80px;
      height: 1px;
      flex-shrink: 0;
    }
  }
}

.item {
  margin: 0 16px;
  padding: 9px;

  display: flex;
  align-items: center;

  width: calc(25% - 32px);

  cursor: pointer;

  border: 1px solid $neutrals6;

  // @include dark {
  border-color: $neutrals3;
  border-radius: 32px;

  transition: all 0.2s;
  flex: 0 0 calc(25% - 32px);

  &:not(:last-child) {
    @include d {
      margin-right: 20px;
    }
  }

  &:hover {
    box-shadow: 0 24px 24px -16px rgba(15, 15, 15, 0.2);
  }

  &:hover {
    border-color: $neutrals4;
  }

  @include d {
    margin: 0;

    width: 256px;
    flex: 0 0 256px;
  }
  // }
}

.picture {
  margin-right: 12px;

  width: 44px;
  height: 44px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.category {
  @include body-bold-2;
}

.text {
  @include caption-2;
  color: $neutrals4;
}
