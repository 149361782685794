@import "../../../../styles/helpers";

.modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title {
  @include SairaBold;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.375rem;

  @include dark {
    color: $light;
  }
  // overflow-wrap: break-word;
  padding-bottom: .25rem;
}

.label {
  font-weight: 600;
  font-size: .875rem;
  line-height: .75rem;

  color: $neutrals2;
  @include dark {
    color: $light;
  }
}

.rowPaymentMethodButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  width: 100%;

  .paymentMethodButton {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0em;

    border-radius: 6.25rem;
    width: 50%;

    &.selected {
      background-color: transparent;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: 6.25rem;
        padding: 2px;
        margin: -2px;
        background: $gradient-gallery;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }

    @include s {
      padding: 0 1rem;
    }

    @media only screen and (max-width: 410px) {
      padding: 0 .75rem;
    }
  }
}

.button {
  width: 100%;
}
