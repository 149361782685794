@import "../../styles/helpers";

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0 3rem;

  @include d {
    padding: 0 2rem;
  }
  @include t {
    padding: 0 1rem 0 3rem;
  }
  @include a {
    flex-grow: 0;
    padding: 0 .325rem;
    width: 3.325rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus ,
  &:focus-within
  {
    @include a {
      .searchBox {
        background-color: $light;
        @include dark {
          background-color: $dark;
        }
      }
      padding: 0;
      flex-grow: 1;
      position: absolute;
      z-index: 500;
      width: 93%;
    }
    @include s {
      margin-left: -10px;
      width: 95%;
    }

    .searchContainer{
      cursor: auto;
      color: $orange-kephi;
      box-shadow: 0 0 4px 1px $neutrals4;
      @include dark {
        color: $light-fifty;
        box-shadow: 0 0 4px 1px $light-fifty;
      }
      transition: opacity 0.3s all;

      .inputSearch,
      .cleanIcon,
      .results {
        display: flex;
      }
    }
  }
}

.searchContainer {
  position: relative;
  z-index: 3;
  align-items: center;
  vertical-align: inherit;

  color: $light-twenty-five;
  border-width: 2px;
  border-style: solid;
  border-radius: 30px;

  transition: box-shadow 1s;
  flex-grow: 1;

  &:hover {
    cursor: pointer;
    color: $orange-kephi;
    @include dark {
      color: $light-fifty;
    }
  }

  @include dark {
    color: $light-twenty-five;
  }

  overflow: visible;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $light-fifty;

  height: 2.25rem;

  vertical-align: inherit;

  border-radius: 30px;

  transition: 0.5s;
  -webkit-box-flex: 1;
  -webkit-box-align: center;

  overflow: hidden;

  background-color: transparent;
}

.inputSearch {
  padding-left: 35px;

  display: flex;

  width: 100%;
  height: 2.25rem;
  height: 2.438rem;

  letter-spacing: 0.5px;
  font-size: inherit;

  background-color: transparent;
  border-radius: 30px;
  outline: none;

  transition: 0.25s;
  -webkit-box-flex: 1;
  flex-grow: 1;

  &::placeholder{
    color: $light-twenty-five;
  }

  &:focus {
    &::placeholder{
      color: $light-fifty;
    }
  }

  @include a {
    font-size: 1rem;
    display: none;
  }

  @include dark {
    border-color: $dark-medium;

    &::placeholder{
      color: $light-twenty-five;
    }

    &:focus {
      &::placeholder{
        color: $light-fifty;
      }
    }
  }
}

.searchIcon {
  position: absolute;
  margin-left: .5rem;
  box-sizing: border-box;
  vertical-align: inherit;
  border-style: none;
  outline: none;
  transition: 0.25s;
  cursor: auto;
  @include a{
    cursor: pointer;
  }
  svg {
    fill: $orange-kephi;
    @include dark {
      fill: $light-twenty-five;
    }
  }
}

.cleanIcon {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;

  box-sizing: border-box;

  line-height: 0;
  vertical-align: inherit;
  outline: none;
  transition: 0.25s;

  svg {
    fill: $neutrals5;
    @include dark {
      fill: $light-twenty-five;
    }
  }

  &:hover {
    svg {
      fill: $orange-kephi;
      @include dark {
        fill: $light-fifty;
      }
    }
  }

  @include a{
    display: none;
  }
}

.results {
  position: absolute;
  top: calc(100% + 4px) !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 500;

  margin: 0;
  padding: 16px 0 16px 0;

  display: none;
  flex-direction: column;

  max-height: 74vh;

  @include m {
    max-height: 80vh;
  }
  @include a {
    max-height: auto;
  }

  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 4px 1px $neutrals4;
  border-width: 1px;
  border-style: solid;
  color: $orange-kephi;

  transition: all .5s;
  flex-grow: 1;
  inset: 0 auto auto 0;

  @include dark {
    background: $dark;
    color: $light-fifty;
    box-shadow: 0 0 4px 1px $light-fifty;
    border-color: $light-twenty-five;
  }
}