@import "../../../styles/helpers";

.title {
  margin-bottom: 32px;
  @include dark {
    color: $light;
  }
}

.upload {
  display: flex;

  img {
    width: 128px;
    height: 128px;

    border-radius: 160px;
  }

  .empty {
    min-width: 128px;
    height: 128px;

    background: $gradient;
    border-radius: 100%;
  }
}

.wrapper {
  margin-top: 20px;
  margin-right: 24px;
  margin-left: 32px;

  display: flex;
  flex-direction: column;

  .text {
    color: $neutrals4;
    @include dark {
      color: $neutrals5;
    }
  }
}

.file {
  position: relative;

  display: inline-block;
  overflow: hidden;
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 140px;

  opacity: 0;
}

.fieldset {
  margin-top: 32px;

  [role=alert] {
    color: darken($yellow, 20);
    padding: .25rem .25rem .75rem;
    @include dark {
      color: $yellow;
    }
  }

  .field {
    input::placeholder {
      color: $orange-kephi;
    }

    &:not(:last-child) {
      margin-bottom: 32px;

      @include m {
        margin-bottom: 20px;
      }
    }
  }
}

.button {
  margin-top: 32px;

  width: 100%;
}
