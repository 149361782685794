@import "../../styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 60rem !important;

  @include m {
    padding: 0 2rem !important;
  }
  @include a {
    padding: 0 1rem !important;
  }
}