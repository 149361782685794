@import '../../../../../../../styles/helpers.scss';

.container {
  transition: 2s;
  width: 100%;
}

.rowContainer {
  padding: .25rem 0 1rem;
}

.columnRows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
}

.dropdown {
  width: 100%;
}

.rowInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.item {
  width: 50%;
}

.label {
  font-weight: 400;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  height: 100%;

  .priceLabel{
    font-size: .75rem;
    font-weight: bold;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 0.688rem;

    @include dark {
      color: $light-fifty;
    }
  }
}

.inputWrapper {
  .usd {
    margin-left: -.25rem;
    box-sizing: border-box;
    vertical-align: inherit;
    border-style: none;
    outline: none;
  }
  .input {
    flex-grow: 1;
    background: transparent;
    margin-left: .25rem;
    width: 100%;
    height: 100%;
    text-align: left;
    &:focus, &:active, &:hover {
      font-weight: 700;
    }
  }
}

.rowButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0 2.5rem;
}

.button {
  width: 50%;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dark-light;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  margin-top: -1rem;
  cursor: default;


  &_add {
    transition: all 0.2s ease;
    svg {
      width: .625rem;
      path {
        fill: $white;
      }
    }

    &:hover {
      &:not(:disabled) {
        cursor: pointer;
        opacity: .8;
        transition: all 0.2s ease;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &_remove {
    opacity: 0;
    transform: rotate(45deg);
    transition: all 0.2s ease;
    background: none;
    margin: 0 -.5rem 0 -1rem;
    svg {
      path {
        fill: $light-twenty-five;
      }
    }
    &.visible {
      opacity: 1;
      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
    @include dark {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

.buttonGradient {
  border-color: transparent;
  background-color: transparent;
  -webkit-mask:
  linear-gradient($dark 0 0) content-box,
  linear-gradient($dark 0 0);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 5.625rem;
    padding: 2px;
    margin: -2px;
    background: $gradient-gallery;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
}

.artTypeLabel {
  padding-top: .5rem;
  font-weight: 700;
  font-size: .875rem;
  vertical-align: center;
  text-transform: uppercase;
}

.filtersContainer {
  padding: 1rem 0;
}