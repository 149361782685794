@import "../../../styles/helpers";

.user {
  padding: 32px 28px;

  text-align: center;

  background: $light;
  border: 1px solid $neutrals6;
  border-radius: 16px;
  box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);

  @include t {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include dark {
    background: $dark;
    background: $neutrals2;
    border: 2px solid $neutrals3;
  }
}

.avatar {
  position: relative;

  margin: 0 auto 24px;

  width: 160px !important;
  height: 160px !important;

  &Img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.name {
  margin-bottom: 4px;

  @include heading-6;
  color: $dark;
  @include dark {
    color: $light;
  }
}

.code {
  margin-bottom: 20px;

  display: inline-flex;
  align-items: center;
}

.number {
  @include body-16-bold;
  color: $dark;
  @include dark {
    color: $neutrals5;
  }
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;

    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.info {
  margin-bottom: 20px;
  @include caption-2;
  color: $neutrals4;
  @include dark {
    color: $neutrals5;
  }
}

.site {
  display: inline-flex;
  align-items: center;

  @include body-16-bold;
  color: $dark;
  @include dark {
    color: $neutrals5;
  }
  svg {
    margin-right: 8px;
    fill: $neutrals4;
  }
}

.control {
  position: relative;

  margin-bottom: 20px;

  display: inline-block;

  @include r("1024") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  span {
    &:nth-child(2) {
      display: none;
    }
  }

  &.active {
    // background: $orange-kephi;
    svg {
      fill: $light;
    }

    span {
      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        display: inline;
      }
    }
  }

  &.shareButton {
    box-shadow: 0 0 0 2px $neutrals6 inset;

    &.active {
      background: $orange-kephi;
      box-shadow: unset;
    }

    &:hover {
      background: $orange-kephi;
      box-shadow: unset;
    }
  }

  &.moreButton {
    @extend .shareButton;
  }

  &.followingButton {
    color: $neutrals3;
    @include dark {
      color: $neutrals4;
    }
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.socials {
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
}

.social {
  svg {
    fill: $neutrals4;

    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: $orange-kephi;
    }
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.note {
  margin: 0 auto 0;
  padding-top: 20px;

  width: 90%;

  color: $neutrals4;
  border-top: 1px solid $neutrals6;

  @include caption-2;
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;

    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.tooltip {
  position: relative;

  display: inline-block;
  visibility: hidden;

  border-bottom: 1px solid black;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;

  padding: 5px 0;

  visibility: hidden;

  width: 120px;

  text-align: center;

  color: #fff;
  background-color: black;
  border-radius: 6px;
}

.copy:hover .tooltiptext {
  visibility: visible;
}

.box {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: calc(100% + 8px);

  padding: 32px 16px;

  visibility: hidden;

  width: 220px;

  text-align: center;

  background: $light;
  border: 1px solid $neutrals6;
  border-radius: 16px;
  opacity: 0;
  box-shadow: 0 32px 32px -8px rgba(31, 47, 70, 0.12);

  transition: all 0.2s;
  transform: translateX(-50%);

  @include dark {
    background: $neutrals2;
    border-color: $neutrals3;
  }

  &.active {
    visibility: visible;

    opacity: 1;
  }
}

.stage {
  margin-bottom: 24px;

  font-weight: 500;

  color: $black;

  @include dark {
    color: $light;
  }
}

.shareButtonsContainer {
  display: flex;
  justify-content: center;
}

.direction {
  span {
    flex-shrink: 0;
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.followers {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.wrapper {
  display: flex;
  gap: 10px;
}

.text {
  color: $orange-kephi;
}

.circle {
  padding: 0 7px;

  display: flex;

  color: $neutrals4;
  @include dark {
    color: $neutrals5;
  }
  border: 1px solid $orange-kephi;
  border-radius: 50%;
}

.loader {
  margin: 32px auto 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    fill: $neutrals4;
  }
}

.verify {
  position: absolute;
  right: 5px;
  bottom: 5px;

  width: 35px;
  height: 35px;
}
