@import "../../styles/helpers";

.field{
  min-height: 6rem;
}

.wrap {
  position: relative;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: .75rem;

  color: $neutrals5;
  @include dark {
    color: $light;
  }
}

.input {
  padding: 0 14px;

  width: 100%;
  height: 48px;

  background: none;
  border: 2px solid $neutrals6;

  border-color: $neutrals5;
  border-radius: 12px;

  transition: border-color 0.2s;
  @include SairaRegular;
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.5rem;

  @include dark {
    color: $light;
    border-color: $neutrals4;
  }

  @include placeholder {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5rem;
    color: $light-fifty;
  }

  &:focus {
    border-color: $light;
  }

  &.error {
    border-color: $red-dark;
  }
}

.input_prefix {
  padding-left: 70px;
}

.prefix {
  position: absolute;
  top: calc(50% - 12px);
  left: 24px;
}

.suffix {
  position: absolute;
  top: calc(50% - 12px);
  right: 14px;
}

.prefix, .suffix {
  @include dark {
    color: $neutrals5
  }
}

.errorContainer {
  clear: both;
  color: $dark;
  font-size: 14px;
  // line-height: 1.5715;
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  // height: 0;
  // min-height: 0;
  opacity: 0;

  &.error {
    // height: auto;
    // min-height: 2rem;
    opacity: 1;
  }
}
.errorLabel {
  font-size: .85rem;
  font-weight: 600;
  line-height: .875rem;
  letter-spacing: 0em;
  text-align: left;

  margin: .25rem 0 0;

  color: $red-dark;
  // @include dark {
  //   color: $red-dark;
  // }
}