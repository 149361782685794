@import '../../styles/helpers.scss';

.modalSuccessOuter {
  max-width: 21.125rem;
}

.modalOuter{
  min-width: 42.688rem;

  @include m {
    width: fit-content;
    min-width: 0;
  }
}
.modalContainer{
  padding: 1.75rem 1.5rem;

  @include a {
    padding: 2rem;
  }

  @include s {
    padding: 1rem;
  }
}