@import "../../styles/helpers";

.actions {
  position: relative;

  &.active {
    .button {
      background: $orange-kephi;
      box-shadow: inset 0 0 0 2px $orange-kephi;

      svg {
        fill: $light;
      }
    }

    .body {
      visibility: visible;

      opacity: 1;
    }
  }
}

.body {
  position: absolute;
  top: 50%;
  right: calc(100% + 20px);

  padding: .5rem .5rem .5rem;

  visibility: hidden;

  width: 208px;

  background: $light;
  border-radius: 12px;
  opacity: 0;
  box-shadow: 0 16px 64px -16px rgba(31, 47, 70, 0.3);
  box-shadow: 0 0 0 2px $neutrals6 inset;

  @include dark {
    background: $neutrals2;
    box-shadow: 0 0 0 2px $neutrals3 inset;

    transition: all 0.2s;
    transform: translateY(-50%);
  }
}

.item {
  padding: 16px 0 12px;

  display: flex;
  align-items: center;

  cursor: pointer;

  color: $neutrals4;

  transition: color 0.2s;

  @include button-2;

  svg {
    margin-right: 8px;

    transition: fill 0.2s;
    fill: $neutrals4;
  }

  &:hover {
    color: $pink;

    svg {
      fill: $pink;
    }
  }

  &:not(:last-child) {
    @include dark {
      border-color: $neutrals3;
      border-bottom: 1px solid $neutrals6;
    }
  }
}
