@import "../../styles/helpers";

.dropdown {
  position: relative;
  z-index: 3;

  &.active,
  &.focus-within {
    z-index: 10;

    .head {
      .arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .body,
    .bodyContainer {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.head {
  position: relative;

  padding: 0 48px 0 16px;

  height: 48px;

  font-size: 14px;
  line-height: 48px;

  cursor: pointer;

  border-radius: 100px;
  box-shadow: inset 0 0 0 1px $neutrals6;

  transition: box-shadow $animation-timing-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include dark {
    box-shadow: inset 0 0 0 2px $light-fifty;
  }

  &:hover,
  &:active,
  &:focus-within {
    box-shadow: inset 0 0 0 2px $orange-kephi;
    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals5;
    }
    .arrow{
      box-shadow: inset 0 0 0 2px $orange-kephi;
      svg {
        fill: $orange-kephi;
      }
      @include dark {
        box-shadow: inset 0 0 0 2px $neutrals5;
        svg {
          fill: $neutrals5;
        }
      }
    }
  }
}

.selection {
  overflow: hidden;
  color: $neutrals3;

  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  @include dark {
    color: $light;
  }

  &.placeholder {
    color: $light-fifty;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $neutrals6;

  transform: translateY(-50%);

  svg {
    fill: $neutrals4;

    transition: transform $animation-timing-default;
  }

  @include dark {
    box-shadow: inset 0 0 0 2px $light-fifty;

    svg {
      fill: $light-fifty;
    }
  }
}

.bodyContainer{
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  left: 0;

  padding: .5rem 0;
  background: $light;
  border-radius: 12px;
  opacity: 0;
  box-shadow: 0 0 0 1px $neutrals6, 0 4px 12px rgba($neutrals2, 0.1);

  visibility: hidden;

  transition: all $animation-timing-default;
  transform: translateY(3px);

  @include dark {
    background: $dark;
    box-shadow: 0 0 0 1px $neutrals3, 0 4px 12px rgba($dark, 0.1);
  }
}

.body {
  max-height: 12rem;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 1px;

  &::-webkit-scrollbar {
    width: 5px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    padding: 8px 0;
    background-color: $neutrals4 !important;
    background: $neutrals4 !important;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $neutrals6 !important;
    background: $neutrals6 !important;
    @include dark {
      background-color: $dark !important;
      background: $dark !important;
    }
  }
}

.option {
  padding: .25rem 1rem;

  font-size: 14px;
  color: $neutrals4;

  cursor: pointer;

  transition: color $animation-timing-default;
  -webkit-tap-highlight-color: $neutrals3;

  &:hover,
  &.selected {
    font-weight: bold;
    color: $neutrals4;
    @include dark{
      color: $neutrals5;
    }
  }
}

.image {
  width: 2.5em;
  padding: .1rem 0;
}

.text {
  color: $neutrals3;
  margin-left: 10px;
  @include dark{
    color: $neutrals4;
    &:hover {
      color: $neutrals5;
    }
  }
}

.selectioned {
  font-weight: bold;

  color: $neutrals4;
}
