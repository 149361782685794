@import "../../styles/helpers.scss";

.link {
  margin: 0 .375rem;
  padding: .375rem .75rem;

  color: $light-fifty;
  background: none;
  border-radius: 10px;

  transition: all 0.2s;
  flex-shrink: 0;

  @include button-2;

  @include m {
    margin: 0;
    padding: .375rem;
  }

  &:hover {
    color: $orange-kephi;

    @include dark {
      color: $neutrals6;
    }
  }

  &.active {
    color: $light;
    background: $orange-kephi;

    @include dark {
      background: $dark-light;
      color: $white;
    }
  }

  &:not(:last-child) {
    margin-right: 12px;

    @include d {
      margin-right: 0;
    }
    @include t {
      margin-right: 12px;
    }
  }
}
