@import "../../styles/helpers";

.uploader {
  position: relative;

  width: 100vw;
  height: 100vh;

  div {
    display: block !important;

    width: 100%;
    height: 100%;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.preview {
  width: 100%;
}

.button {
  margin-top: 20px;
  height: 2.5rem !important;
  padding: .5rem 2rem !important;

  width: 100%;
}


.successMessage, .errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 auto;
    padding: .25rem 1rem;
    border-radius: 1rem;
    color: $neutrals3;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.errorMessage{
  div {
    background-color: $red-light;

    @include dark {
      background-color: $red-gray;
      color: $light;
    }
  }
}
.successMessage {
  div {
    background-color: $green-light;

    @include dark {
      background-color: $green-gray;
      color: $light;
    }
  }
}