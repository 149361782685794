@import "../../styles/helpers";

.card {
  display: flex;
  flex-direction: column;

  background-color: $white;
  border-radius: 12px;

  max-width: 100%;

  &:hover {
    .control {
      visibility: visible;

      opacity: 1;
    }

    .preview {
      & > img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  @include dark {
    background: $dark;
  }
}

.preview {
  position: relative;

  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  height: 303px;

  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s;
  }
  @include t {
    // height: 400px;
  }
  @include m {
    // height: 500px;
  }
  @include a {
    // height: 450px;
    height: 60vh;
  }
  @include s {
    height: 40vh;
  }
}

.control {
  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;

  width: 100%;
  height: 100%;

  background-color: rgba($neutrals3, 0.4);
  opacity: 0;

  transition: all 0.2s;
}

.chain {
  position: absolute;
  top: 11px;
  left: 8px;
}

.favorite {
  position: absolute;
  top: 8px;
  right: 8px;

  width: 32px;
  height: 32px;

  background: $light;
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);

  svg {
    width: 20px;
    height: 20px;

    transition: fill 0.2s;
    transition: opacity 0.2s;
    fill: $neutrals4;
  }

  &::before {
    content: "";

    position: absolute;
    top: 51%;
    left: 50%;

    width: 18px;
    height: 18px;

    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 100% auto;
    opacity: 0;

    transition: opacity 0.2s;
    transform: translate(-50%, -50%);
  }

  &:hover {
    svg {
      fill: $pink;
    }
  }

  &.liked {
    svg {
      fill: $pink;
    }
  }

  &.active {
    svg {
      opacity: 0;
    }

    &::before {
      opacity: 1;
    }
  }

  @include dark {
    background: $neutrals2;

    &:hover {
      svg {
        fill: $light;
      }
    }
  }
}

.button {
  position: absolute;
  left: 50%;
  bottom: 16px;

  transform: translateX(-50%);
}

.link {
  padding: 20px 10px 13px;

  display: flex;
  flex-direction: column;

  color: $neutrals2;
  flex-grow: 1;

  @include dark {
    color: $light;
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include dark {
    color: $light;
    & .line, .foot {
      color: $light;
    }
  }
}

.line {
  display: flex;
  align-items: flex-start;

  &:first-child {
    margin-bottom: 12px;
  }

  &:nth-child(2) {
    margin-top: auto;
  }
}

.title {
  margin-right: auto;
  padding-top: 1px;

  overflow: hidden;

  max-width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: $neutrals2;

  @include body-bold-2;
  @include SairaRegular;
  font-weight: 500;

  @include dark {
    color: $neutrals6;
  }
}

.price {
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;

  border-radius: 4px;
  flex-shrink: 0;
}

.inner {
  padding: 0 6px;

  border: 2px solid $orange-kephi;
  border-radius: 3px;
}

.innerText {
  color: $orange-kephi;
}

.users {
  margin-right: auto;

  display: flex;
}

.avatar {
  display: flex;

  width: 24px;
  height: 24px;

  border: 2px solid $light;
  border-radius: 50%;
  flex-shrink: 0;

  @include dark {
    border-color: $neutrals2;
  }

  img {
    width: 21px;
    height: 21px;

    border-radius: 50%;
    object-fit: cover;
  }

  &:not(:first-child) {
    margin-left: -8px;
  }
}

.counter {
  @include body-14;
  @include poppins;
  color: $neutrals3;

  svg {
    fill: transparent;
    stroke: $orange-kephi;
  }

  @include dark {
    color: $neutrals5;
  }
}

.likesCounter {
  @include body-14;
  color: $neutrals3;

  svg {
    fill: transparent;
    stroke: $orange-kephi;
  }
}

.foot {
  position: relative;

  margin-top: 12px;
  padding-top: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $neutrals4;
  border-top: 1px solid $neutrals6;

  @include caption-2;

  @include dark {
    border-color: $neutrals3;
  }
}

.status {
  @include caption-2;
  color: $neutrals4;

  svg {
    margin: (-2px) 4px 0 0;
    fill: $neutrals4;
  }

  span {
    font-weight: 700;

    color: $neutrals3;

    @include dark {
      color: $light;
    }
  }
}

.bid {
  font-weight: 700;
  line-height: 17.07px;

  color: $black;
  @include body-14;
}
