@import "../../styles/helpers";

.title {
  margin-bottom: 32px;
  @include dark {
    color: $light;
  }
}

.text {
  margin-bottom: 32px;

  @include body-2;
  color: $neutrals4;
}

.info {
  margin-bottom: 3px;

  @include body-bold-1;
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.field {
  color: $neutrals2;
}
