@import "../../../styles/helpers";

.container {
  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 33.333%);
  grid-gap: 20px;
  @include t {
    grid-template-columns: repeat(2, 50%);
  }
  @include a {
    grid-template-columns: 1fr;
  }
}

.details {
  max-width: 100%;
}

.item {
  display: flex;
  flex: 0 0 26%;

  @include x {
    flex: 1 0 31%;
  }

  @include t {
    flex: 0 0 26%;
  }

  @include r(879) {
    flex: 1 0 31%;
  }

  @include a {
    position: relative;
    flex: initial;

    width: 100%;
  }

  @include dark {
    border-color: $neutrals3;
  }
}

.follower {
  display: flex;
  align-items: center;

  width: 100%;

  @include a {
    padding-right: 0;

    width: 100%;
  }
}

.avatar {
  margin-right: 20px;

  width: 88px;
  height: 88px;
  flex-shrink: 0;

  @include a {
    margin-right: 16px;

    width: 72px;
    height: 72px;
  }

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.title {
  @include body-16-bold;
  display: block;
  overflow: hidden;

  max-width: 160px;

  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $orange-kephi;
  }

  @include dark {
    color: $light;
    &:hover {
      color: $orange-kephi;
    }
  }
}

.counter {
  @include body-14;
  color: $neutrals4;
}

.button {
  margin-top: 12px;

  @include a {
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: 0;

    transform: translateY(-50%);
  }

  &.followingButton {
    color: $neutrals3;
  }
}

.preview {
  img {
    width: 100%;
  }
}

.loader {
  margin: 3rem auto 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    fill: $neutrals4;
  }

  @include t {
    margin: 3rem auto 10px;
  }
  @include m {
    margin: 2.5rem auto 10px;
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: $neutrals3;
  @include body-14;

  @include dark {
    color: $neutrals5;
  }
}
