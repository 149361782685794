$slick-arrow-width: 40px;
$slick-arrow-margin: 4px;
$slick-arrow-width-with-margins: $slick-arrow-width + $slick-arrow-margin * 2;

.slick-slider {
  position: relative;

  display: block;

  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;

  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;

  margin: 0;
  padding: 0;

  display: block;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  margin-right: auto;
  margin-left: auto;

  display: block;

  &::before {
    content: "";

    display: table;
  }

  &::after {
    content: "";

    display: table;
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow.slick-disabled {
  box-shadow: unset !important;

  &:hover {
    box-shadow: unset;
  }
}

.slick-arrow {
  position: absolute;
  z-index: 2;

  width: 40px;
  height: 40px;

  font-size: 0;

  cursor: pointer !important;

  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $neutrals6;

  transition: all 0.2s;

  svg {
    position: relative;
    z-index: 2;

    cursor: pointer !important;

    transition: fill 0.2s;
    fill: $neutrals4;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px $orange-kephi;
  }

  @include dark-common {
    box-shadow: inset 0 0 0 2px $light-twenty-five;
    svg {
      fill: $light-twenty-five;
    }
    &:hover {
      box-shadow: inset 0 0 0 2px $light;

      svg {
        fill: $light;
      }
    }
  }

  // &.slick-disabled
  //     opacity: .7
}

// creative slider
.creative-slider {
  position: static;

  // :hover {
  //   // cursor: grab;
  // }

  // :active {
  //   // cursor: grabbing;
  // }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding: 0;

    opacity: 0;

    transition: opacity 0.4s;

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: 4px;

    @include t {
      top: auto;
      bottom: -4rem;
    }

    border-radius: 50%;

    @include dark-common {
      background: transparent;
    }
  }

  .slick-prev {
    right: 48px;
    @include t {
      right: auto;
      left: calc(50% - #{$slick-arrow-width-with-margins});
    }
  }

  .slick-next {
    right: 0;

    @include t {
      right: calc(50% - #{$slick-arrow-width-with-margins});
    }
  }
}

// popular slider
.popular-slider {
  position: static;

  @include t {
    height: 20rem;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin: 0 .5rem;
    height: auto;
    opacity: 0;
    transition: opacity 0.4s;

    &.slick-active {
      opacity: 1;
    }

    & > div {
      display: flex;

      height: 100%;
      flex-grow: 1;
    }
  }

  .slick-arrow {
    top: 4px;

    @include m {
      top: auto;
      bottom: 0;
    }
  }

  .slick-prev {
    right: 48px;

    @include m {
      right: auto;
      left: calc(50% - #{$slick-arrow-width-with-margins});
    }
  }

  .slick-next {
    right: 0;

    @include m {
      right: calc(50% - #{$slick-arrow-width-with-margins});
    }
  }
}

// hot-bids slider
.hot-bids-slider {
  position: static;

  .slick-list {
    margin: 0 -16px;

    overflow: visible;

    @include m {
      margin: 0 -8px;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 16px;

    height: auto;

    opacity: 0;

    transition: opacity 0.4s;

    @include m {
      margin: 0 8px;
    }

    &.slick-active {
      opacity: 1;
    }

    & > div {
      display: flex;

      height: 100%;
      flex-grow: 1;
    }
  }

  .slick-arrow {
    top: 4px;
  }

  .slick-prev {
    right: $slick-arrow-width-with-margins;
  }

  .slick-next {
    right: 0;
  }
}

// collection slider
.collection-slider {
  position: static;

  @include m {
    padding-bottom: 72px;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 1rem;

    height: auto;

    opacity: 0;

    transition: opacity 0.4s;

    &.slick-active {
      opacity: 1;
    }

    & > div {
      display: flex;

      height: 100%;
      flex-grow: 1;

      @include m {
        justify-content: center;
      }
    }
  }

  .slick-arrow {
    top: 4px;

    @include m {
      top: auto;
      bottom: 0;
    }
  }

  .slick-prev {
    right: 48px;

    @include m {
      right: auto;
      left: calc(48% - #{$slick-arrow-width-with-margins});
    }
  }

  .slick-next {
    right: 0;

    @include m {
      right: calc(48% - #{$slick-arrow-width-with-margins});
    }
  }
}

// discover slider
.discover-slider {
  .slick-list {
    padding-bottom: 72px;

    overflow: visible;
  }

  .slick-slide {
    padding: 0 8px;
  }

  .slick-arrow {
    bottom: 0;
  }

  .slick-prev {
    left: calc(50% - #{$slick-arrow-width-with-margins});
  }

  .slick-next {
    right: calc(50% - #{$slick-arrow-width-with-margins});
  }
}
