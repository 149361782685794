@import "../../../styles/helpers";

@mixin button {
  padding: 0 1.5rem;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 48px;

  width: calc(50% - 8px);
  flex: 0 0 calc(50% - 8px);
  display: flex;
  flex-wrap: wrap;

  color: $white !important;

  border-radius: 1.5rem;

  transition: all 0.2s;

  @include button-1;

  @include a {
    width: 100%;
    height: 48px !important;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
  @include s {
    font-size: .875rem;
  }

  &:hover {
    color: $white !important;
  }
}

.control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem;
  height: fit-content;

  border: 2px solid $lightgray;
  border-radius: 10px;
  box-shadow: 0 64px 64px -24px rgba(31, 47, 70, 0.12);

  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);

  @include m {
    padding: 1.125rem 1rem;
  }

  @include dark {
    border-color: $neutrals3;
  }

  .button {
    @include button;
    padding: 0 .5rem;

    width: calc(50% - 8px);
    flex: 0 0 calc(50% - 8px);
    display: flex;
    flex-wrap: wrap;
    color: $white;

    &:only-child {
      width: 100%;
      flex-basis: auto;
    }

    &:not(:last-child) {
      margin-right: .5rem;
    }

    &:hover {
      color: $white;
    }

    @include s {
      font-size: .875rem;
    }
  }

  .placeBidButton {
    @include button-1;
    color: $neutrals3;

    &:hover {
      color: $white;
    }

    @include s {
      font-size: .875rem;
    }
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.avatar {
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.info {
  display: flex;
  flex-direction: column;
  @include body-14;
  font-weight: 500;

  color: $neutrals4;

  span {
    color: $black;

    @include dark {
      color: $light;
    }
  }
  @include a {
    font-size: .875rem;
    line-height: 1.25rem;
  }
  @include dark {
    color: $light-fifty;
  }
}
.minimalBid {
  @include SairaRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .35rem 1.75rem;

  border: 1px solid $orange-kephi;
  border-radius: 6.25rem;

  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: center;
  width: fit-content;
}

.cost {
  display: flex;
  align-items: center;
}

.price, .priceBold, .highestBid {
  // font-size: 1.75rem;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.625rem;
  color: $neutrals3;

  @include dark {
    color: $light;
  }

  // Posta
  // &:nth-child(2) {
  //   color: $light-twenty-five;
  // }
  // Posta

  &:not(:last-child) {
    margin-right: 1rem;
  }

  @include a {
    font-size: 1.5rem;
    line-height: 1.25rem;
  }
  @include s {
    font-size: 1.375rem;
  }
}
.price {
  font-size: 1rem;
  @include dark {
    color: $light-fifty;
  }
}
.highestBidDetails {
    display: flex;
    gap: 1rem;
}

.highestBid{
  font-size: 1.5rem;
  @include a {
    font-size: 1.375rem;
  }
  @include s {
    font-size: 1rem;
  }
}
.priceBold {
  @include SairaExtraBold;
}
.btns {
  display: flex;
  width: auto;
  margin: 1.25rem 0 0;
  align-items: center;
}

.text {
  @include body-16;
  font-weight: 600;
  margin-top: 15px;

  color: $neutrals4;

  span {
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
  @include a {
    font-size: 13px;
  }
}

.label {
  font-weight: 500;
}

.percent {
  font-weight: 500;

  color: $neutrals3;

  @include dark {
    color: $light;
  }
}

.foot {
  margin-top: 1rem;

  .button {
    width: 100%;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
  margin-top: .5rem;
  border-top: 1px solid $dark-light;
}

.note {
  margin-top: 12px;
  font-weight: 500;

  color: $neutrals4;
  @include a {
    font-size: 13px;
  }
}

.outlinedButton {
  text-align: center;
  width: 100%;

  background-color: transparent;
  border: 2px solid $lightgray;
  @include button;
  color: $neutrals3;

  &:hover {
    background-color: darken($white, 10%);
  }
  @include dark {
    border-color: $neutrals4;
    color: $light;
    &:hover {
      border-color: $light;
      background-color: $neutrals4;
    }
  }
}

.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: $neutrals3;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.betaVersion{
  position:relative;
  display: inline-block;

  text-align:center;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  padding: 0 2.5rem;

  @include x {
    padding: 0 2.35rem;
  }
  @include a {
    padding: 0 3rem;
  }
  @include s {
    padding: 0 1.35rem;
  }

  &::after {
    content: "beta";
    position: absolute;
    text-transform: uppercase;
    top: .275rem;
    right:-.9rem;

    height:0;
    border-bottom: .9rem solid $blue;
    border-bottom: .9rem solid $red-dark;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    transform: rotate(45deg);
    z-index: -100;
    line-height: 1.125rem;
    font-size: .5rem;
    @include SairaRegular;
    font: 'Lato', sans-serif;
    width: min-content;
    text-align: center;
    color: $light;
  }
}

.btnsSkeleton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}
.buttonSkeleton {
  height: 2.5rem;
  border-radius: 1.25rem !important;
  margin-right: 1rem !important;
  width: 10rem !important;

  @include m {
    width: 9rem !important;
  }
  @include a {
    width: 8rem !important;
  }
}

.loginButton {
  span {
    display: block !important;
  }
}

.paymentMethodModal {
  padding-bottom: 2.25rem;
}