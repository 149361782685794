@import "../../../styles/helpers";

.container {
  @include m {
    // padding-right: 0 !important;
  }
}

.title {
  margin-bottom: 2.25rem;

  color: $neutrals2;
  @include dark {
    color: $light;
  }
}
.description {
  margin-bottom: 2.75rem;
  @include SairaRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: $neutrals2;
  @include dark {
    color: $light;
  }
}
.slideCard {
  width: 100%;
}

.card {
  box-sizing: border-box;
  border: 1px solid $light-twenty-five;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);
  border-radius: 10px;
  position: relative;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: .5s;

  transition: .5s;
  img {
    transition: .5s;
  }

  &:hover {
    .kephiLogo, .zurbaranLogo, .label {
      transition: .5s;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    &:not(.comingSoon) {
      cursor: pointer;
      transition: .5s;
      border: 1px solid transparent;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      position: relative;

      &::before {
        transition: .5s;
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: 10px;
        padding: 1px;
        background: $gradient-gallery;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
      }
    }
  }

  .kephiLogo {
    width: 19.563rem;
  }
  .zurbaranLogo {
    width: 13.375rem;
  }

  height: 21.875rem;
  width: 33.333%;
  @include w {
    height: 18.875rem;
    .kephiLogo {
      width: 18.563rem;
    }
    .zurbaranLogo {
      width: 12.375rem;
    }
  }
  @include x {
    height: 18.875rem;
    .kephiLogo {
      width: 17.563rem;
    }
    .zurbaranLogo {
      width: 11.375rem;
    }
  }
  @include d {
    height: 17.875rem;
    .kephiLogo,
    .zurbaranLogo,
    .label {
      margin-top: 1rem;
    }
    .kephiLogo {
      width: 15.563rem;
    }
    .zurbaranLogo {
      width: 10.375rem;
    }
  }
  @include t {
    height: 15.875rem;
    .kephiLogo,
    .zurbaranLogo,
    .label {
      margin-top: 1.5rem;
    }
    .kephiLogo {
      width: 12rem;
    }
    .zurbaranLogo {
      width: 9rem;
    }
  }
  @include m {
    height: 15.5rem;
    .kephiLogo,
    .zurbaranLogo,
    .label {
      margin-top: 1.75rem;
    }
    .kephiLogo {
      width: 10.5rem;
    }
    .zurbaranLogo {
      width: 8rem;
    }
  }

  @include s {
    height: 15rem;
    .kephiLogo {
      width: 11.5rem;
    }
    .zurbaranLogo {
      width: 8rem;
    }
  }

  @include m {
    width: 100%;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &.comingSoon {
    &::before {
      content: "Coming Soon";
      text-transform: uppercase;
      position: absolute;
      font-size: .875rem;
      font-weight: 400;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(29.29%, -100%) rotate(45deg);
      color: $light;
      text-align: center;
      border: 1px solid transparent;
      border-bottom: 0;
      transform-origin: bottom left;
      padding: 6px 4rem 11px;
      background: $gradient-gallery;
      background-clip: padding-box;
      clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - 1px) calc(100% - 6px),1px calc(100% - 6px),0 100%);
      -webkit-mask: linear-gradient(135deg,transparent calc(50% - 4px * 0.707),#fff 0) bottom left,
        linear-gradient(-135deg, transparent calc(50% - 4px * 0.707), #fff 0)bottom right;
      -webkit-mask-size: 300vmax 300vmax;
      mask-composite: intersect;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;

      @include d {
        font-size: .675rem;
        padding: 0 3.75rem .313rem;
        height: 2.75rem;
      }
      @include t {
        font-size: .675rem;
        padding: 0 3rem .313rem;
        height: 2.5rem;
      }
      @include m {
        font-size: .5rem;
        padding: 0 2.5rem .313rem;
        height: 2rem;
      }
    }
  }

}

.typeContainer {
  position: absolute;
  top: .75rem;
  margin: 0 -.75rem;
  padding: 1rem;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.1) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  // backdrop-filter: blur(5.5px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid $light-twenty-five;
  border-radius: 10px;
  transform: rotate(180deg);
  width: 94%;

  @include d {
    padding: .9rem;
  }
  @include t {
    padding: .875rem 0;
  }
  @include m {
    padding: .675rem 0;
  }
  @include s {
    padding: .5rem 0;
  }
}
.type {
  transform: rotate(-180deg);
  @include SairaRegular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
  text-align: center;
  color: white;

  @include t {
    font-size: .875rem;
  }
}

.label {
  transition: .5s;
  @include Handjet;
  font-style: normal;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 3.125rem;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  background: $gradient-gallery;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  overflow: visible;
  max-width: 11.875rem;

  @include w {
    font-size: 3.625rem;
  }
  @include x {
    font-size: 3.5rem;
  }
  @include d {
    font-size: 3.25rem;
  }
  @include t {
    font-size: 2.9rem;
  }
  @include a {
    font-size: 2.5rem;
    line-height: 2.25rem;
    max-width: 8.875rem;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  gap: 1.25rem;

  @include m {
    display: none;
  }
}

.sliderWrapper {
  display: none;

  @include m {
    display: block;
    margin-right: -3rem;
  }
  @include a {
    margin-right: -2rem;
  }
}