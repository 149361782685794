.container {
  margin: 0 auto;
  padding: 0 80px;

  width: 100%;
  max-width: 1440px;

  @include d {
    padding: 0 75px;
  }

  @include t {
    padding: 0 60px;
  }

  @include m {
    padding: 0 3rem;
  }

  @include a {
    padding: 0 2rem;
  }
}
