@import "../../../../styles/helpers";

.title {
  margin-bottom: 32px;
  padding-top: 40px;

  text-align: center;
}

.info {
  margin: 0 auto 1.5rem;

  max-width: 350px;

  text-align: center;

  @include body-bold-2;

  span {
    text-decoration: underline;
  }
}

.note {
  display: inline-flex;
  font-size: .8rem;
  padding: .25rem .75rem;
  margin: 0 1rem 1rem;
  border-radius: .75rem;

  .infoIcon {
    padding-right: .25rem;
    svg{
      fill: darken($blue-light, 20);
    }
  }
}

.table {
  margin: .5rem 0 2rem;
  padding: 24px;

  border: 1px solid $neutrals6;
  border-radius: 12px;

  @include dark {
    border-color: $neutrals4;
  }
}

.row {
  display: flex;

  &:first-child {
    color: $neutrals4;
  }

  &:nth-child(2) {
    font-weight: 500;

    .col {
      &:first-child {
        color: $orange-kephi;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.col {
  &:first-child {
    margin-right: auto;
    padding-right: 16px;
  }

  &:nth-child(2) {
    flex-shrink: 0;

    width: 152px;
  }
}

.stage {
  margin-bottom: 16px;

  text-align: center;

  @include body-bold-2;
}

.socials {
  display: flex;
  justify-content: center;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;

  @include dark {
    border-color: $neutrals3;
    svg {
      fill: $neutrals4;
    }
  }

  svg {
    fill: $neutrals3;
  }
  &:hover {
    svg {
      fill: $orange-kephi;
    }
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;

    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.tooltip {
  position: relative;

  display: inline-block;
  visibility: hidden;

  border-bottom: 1px solid black;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;

  padding: 5px 0;

  visibility: hidden;

  width: 120px;

  text-align: center;

  color: #fff;
  background-color: black;
  border-radius: 6px;
}

.copy:hover .tooltiptext {
  visibility: visible;
}
