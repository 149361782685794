// DMSans
@font-face {
  font-family: DMSans;
  src: url(../assets/fonts/DM_Sans/DMSans-Medium.ttf)
}
@mixin DM-Sans {
  font-family: DMSans;
}

// Poppins
@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins/Poppins-Regular.ttf)
}
@mixin poppins {
  font-family: Poppins;
}
@font-face {
  font-family: poppinsMedium;
  src: url(../assets/fonts/Poppins/Poppins-Medium.ttf)
}
@mixin poppinsMedium {
  font-family: poppinsMedium;
}

// Brother1816
@font-face {
  font-family: Brother1816;
  src: url(../assets/fonts/Brother1816/Brother1816-Regular.ttf)
}
@mixin brother-1816 {
  font-family: Brother1816;
}

// Saira
@font-face {
  font-family: SairaRegular;
  src: url(../assets/fonts/Saira/Saira-Regular.ttf)
}
@mixin SairaRegular {
  font-family: SairaRegular;
}
@font-face {
  font-family: SairaBold;
  src: url(../assets/fonts/Saira/Saira-Bold.ttf);
}
@mixin SairaBold {
  font-family: SairaBold;
}
@font-face {
  font-family: SairaExtraBold;
  src: url(../assets/fonts/Saira/Saira-ExtraBold.ttf);
}
@mixin SairaExtraBold {
  font-family: SairaExtraBold;
}

// Handjet
@font-face {
  font-family: Handjet;
  src: url(../assets/fonts/Handjet/Handjet.ttf);
}
@mixin Handjet {
  font-family: Handjet;
}