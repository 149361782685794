[class^="status"] {
  padding: 0 8px;

  display: inline-block;

  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;

  color: $light;
  border-radius: 4px;
}

.status {
  &-green {
    background: $green;
  }

  &-purple {
    background: $purple;
  }

  &-black {
    background: $neutrals2;
  }

  &-stroke {
    &-black {
      @include hairline-2;
      color: $neutrals2;
      box-shadow: inset 0 0 0 2px $neutrals6;
    }

    &-gradient {
      margin-left: 8px;
      padding: 2px;

      font-size: 12px;
      font-weight: 700;
      line-height: 26px;
      text-transform: uppercase;

      background: $gradient;
      border-radius: 4px;
      flex-shrink: 0;
    }
  }
}
