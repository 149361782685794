@import '../../../../styles/helpers.scss';

.title {
  font-size: .875rem;
  font-weight: bold;
  text-align: center;
}

.rowContainer {
  padding: .5rem 0 1.5rem;
  border-bottom: 1px solid $light-twenty-five;
}

.rowFlex {
  display: flex;
  justify-content: space-between;
}

.columnRows {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  .rangeLabel {
    font-size: .875rem;
    font-weight: 700;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 0.688rem;

    @include dark {
      color: $light
    }
  }

  .priceAverage {
    font-size: .75rem;
    font-weight: 400;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;

    @include dark {
      color: $light-fifty;
    }
  }
}

.dropdown {
  width: 12rem;
}

.rowInputs {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem 0 1rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  height: 100%;

  .priceLabel{
    font-size: .875rem;
    font-weight: 700;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 0.688rem;

    @include dark {
      color: $light-fifty;
    }
  }
}

.inputWrapper {
  .usd {
    margin-left: -.25rem;
    box-sizing: border-box;
    vertical-align: inherit;
    border-style: none;
    outline: none;
  }
  .input {
    flex-grow: 1;
    background: transparent;
    margin-left: .25rem;
    width: 100%;
    height: 100%;
    text-align: left;
    &:focus, &:active, &:hover {
      font-weight: 700;
    }
  }
}

.rowButtons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem 0 1rem;
}

.button {
  width: 50%;
}

.artTypeLabel {
  padding-top: .5rem;
  font-weight: 700;
  font-size: .875rem;
  // line-height: 54%;
  vertical-align: center;
  text-transform: uppercase;
}

.filtersContainer {
  padding: 1rem 0;
}