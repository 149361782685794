@import "../../styles/helpers";

.switch {
  position: relative;

  display: inline-block;

  font-size: 0;

  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.input {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;

  &:checked + .inner .box {
    background: $blue;

    &::before {
      background: $light;

      transform: translate(24px, -50%);
    }
  }
}

.inner {
  position: relative;

  display: inline-block;

  transition: all $animation-timing-default;
}

.box {
  position: relative;

  display: block;

  width: 48px;
  height: 24px;

  background: $neutrals6;
  border-radius: 12px;

  transition: all $animation-timing-default;

  @include dark {
    border: 1px solid $neutrals4;
    background: $neutrals3;
  }

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: 4px;

    width: 16px;
    height: 16px;

    background: $blue;
    border-radius: 50%;

    transition: all $animation-timing-default;
    transform: translateY(-50%);
  }
}
