@import "../../styles/helpers";

@mixin button {
  color: $neutrals2;
  box-shadow: 0 0 0 2px $neutrals6 inset;

  svg {
    fill: $neutrals4;
  }

  &:hover {
    background: $orange-kephi;
  }
}

.top {
  display: flex;
  align-items: center;

  @include s {
    flex-direction: column;
  }

  .button {
    // @include body-16-bold;
    color: $neutrals3;

    @include s {
      margin-top: 16px;

      width: 100%;
    }
  }
}

.avatar {
  width: 160px !important;
  height: 160px !important;

  border-radius: 50%;
}

.name {
  margin-left: 16px;

  font-size: 24px;
  line-height: 36px;

  color: $dark;
  @include dark {
    color: $light;
  }
}

.list {
  margin: 0 -16px;

  display: flex;
  flex-wrap: wrap;
  @include d {
    justify-content: center;
  }
  @include m {
    margin: 0 -8px;
  }
}

.card {
  margin: 32px 16px 0;

  max-width: calc(25% - 32px);
  flex: 0 0 calc(25% - 32px);
  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);

  @include d {
    max-width: calc(33.333% - 32px);
    flex: 0 0 calc(33.333% - 32px);
  }

  @include t {
    max-width: calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
  }

  @include m {
    margin: 20px 8px 0;

    max-width: calc(100% - 32px);
    flex: 0 0 calc(100% - 32px);
  }
}

.btns {
  margin-top: 32px;

  text-align: center;

  .button {
    @include button;

    svg {
      margin: 0 0 0 12px !important;
    }
  }
}

.notokens {
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include x {
  }

  @include t {
    width: 100%;
  }
}

.nav {
  margin-top: 15px;
  margin-bottom: 10px;

  @include m {
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.loader {
  margin-top: 30px;
}

.body {
  min-height: 1223px;
}

.zoomContainer {
  dialog {
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    > div {
      background: $neutrals6;
      @include dark {
        background: $neutrals2;
      }
      > img {
        height: 100vh;
        width: 100vw;
        max-height: 100vh;
        max-width: 100vw;
      }
    }
  }
}