@import "../../styles/helpers.scss";

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 1rem 1rem;

  @include s {
    padding: 1rem 1rem;
  }
}

.title {
  @include SairaBold;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 38px;
  color: $light;
}

.subtitle {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: $light-fifty;
  max-width: 12rem;
  text-align: center;
}

.infoPanel {
  display: flex;
  text-align: center;
  padding: .5rem 1rem 0;
}

.button {
  width: 100%;
  padding: 1.5rem 2rem;
  margin-top: .5rem;
  font-weight: 700 !important;

  border: 2px solid transparent;
  -webkit-mask:
  linear-gradient($dark 0 0) content-box,
  linear-gradient($dark 0 0);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 5.625rem;
    padding: 2px;
    margin: -2px;
    background: $gradient-gallery;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
}