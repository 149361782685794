@import "../../../../../styles/helpers";

@mixin button-active-grey {
  color: $neutrals2;

  &:hover {
    color: $white;
  }
}

$default-margin: 2rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  @include t {
    display: block;
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;

  display: flex;
  align-items: center;
  color: $light;
  margin-bottom: .75rem;
}

.option {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 0;
}
.box {
  flex-grow: 1;
}
.flexBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  position: relative;
  width: 100%;
}

.note {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.file {
  position: relative;

  margin-top: 16px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 19.313rem;

  background: $light;
  border-radius: 16px;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }

  @include dark {
    background: $dark-light;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.icon {
  margin-bottom: 10px;

  width: 24px;
  height: 24px;

  svg {
    fill: $neutrals4;
  }
}

.format {
  @include caption-2;
  color: $neutrals4;
}

.category {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;

  color: $neutrals2;
  @include dark {
    color: $light;
  }
}

.switchInput {
  &:checked + .switchInner .switchBox {
    background: $dark-light;

    &::before {
      background: $gradient-gallery;
      transform: translate(22px, -50%);
    }
  }

}
.switchBox {
  background: $dark-light;
  border: none !important;
  height: 20px;

  &::before {
    height: 17px;
    width: 22px;
    left: 2px;
    border-radius: 100px;

    background: $light;
  }
}
.text {
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: $light-fifty;
}
.rowModalityButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .modalityButton {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;

    border-radius: .625rem;
    width: 50%;

    &.selected {
      background-color: transparent;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: .625rem;
        padding: 2px;
        margin: -2px;
        background: $gradient-gallery;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
}

.buttonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.375rem;
  width: 100%;
  padding-top: 2rem;
}
.backButton {
  font-weight: 700 !important;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
}
.nextbutton {
  font-weight: 700;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
  background: $gradient-gallery;
}
.royaltiesField {
  max-width: 4.313rem;
  min-height: 0;
  height: 3rem;
}

.textAreaInput {
  box-sizing: border-box;
  resize: none;
  padding: .5rem .75rem;
  width: 100%;

  background: none;
  border: 2px solid $neutrals6;

  border-color: $neutrals5;
  border-radius: 12px;

  transition: border-color 0.2s;
  @include SairaRegular;
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.5rem;

  @include dark {
    color: $light;
    border-color: $neutrals4;
  }

  @include placeholder {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5rem;
    color: $light-fifty;
  }

  &:focus {
    border-color: $light;
  }

  &.error {
    border-color: $red-dark;
  }
}

.errorContainer, .errorRoyaltiesContainer {
  clear: both;
  color: $dark;
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 0;
  min-height: 0;
  opacity: 0;

  &.error {
    opacity: 1;
  }
}
.errorLabel, .errorRoyaltiesLabel {
  font-size: .85rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: .25rem;

  color: $red-dark;
}

.errorRoyaltiesContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -.75rem;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
}

.paymentMethod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 0px;

  width: 5.325rem;
  height: 5.325rem;
  border: 2px solid $light-twenty-five;
  border-radius: .625rem;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);

  > span {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-align: center;
  }

  &:hover, :not(.selected) {
    cursor: pointer;
    border-color: $light-fifty;
  }
  
  &.selected {
    border-color: transparent;
    background-color: transparent;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: .625rem;
      padding: 2px;
      margin: -2px;
      background: $gradient-gallery;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }
  }
}