@import "../../styles/helpers";

.form {
  position: relative;
}

.input {
  padding: 0 48px 0 14px;

  width: 100%;
  height: 48px;

  color: $neutrals2;
  color: $light;
  background: none;
  border: 2px solid $neutrals6;

  // @include dark {
  border-color: $neutrals3;
  border-radius: 24px;

  transition: border-color 0.2s;

  @include poppins;
  @include caption-1;
  // }
  &:focus {
    border-color: $neutrals4;
  }

  @include placeholder {
    color: $neutrals4;
  }
}

.btn {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;

  width: 32px;
  height: 32px;

  background: $gradient;
  border-radius: 50%;

  transition: background 0.2s;

  svg {
    fill: $light;
  }

  &:hover {
    background: darken($blue, 10);
  }
}
