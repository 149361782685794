@import "../../styles/helpers";

.top {
  display: flex;
  align-items: center;

  @include s {
    flex-direction: column;
  }

  .button {
    // @include body-16-bold;
    color: $neutrals2;

    @include s {
      margin-top: 16px;

      width: 100%;
    }
  }
}

.body {
  flex-grow: 1;
}

.title {
  margin-right: auto;
  color: $neutrals2;
  @include dark {
    color: $light;
  }
}

.wrapper {
  flex-grow: 1;

  padding-top: 64px;

  @include t {
    padding: 0;

    width: 100%;
  }
}

.nav {
  margin: 0 -6px 32px;

  display: flex;
  flex-wrap: wrap;

  @include t {
    margin-top: 40px;
  }

  @include m {
    margin-top: 32px;
  }
}
