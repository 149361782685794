@import "../../styles/helpers";

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  padding: 48px;

  display: flex;
  overflow: auto;

  width: 100%;
  height: 100%;

  background: rgba($dark, .8);
  opacity: 0;

  animation: showModal 0.5s forwards;

  @include m {
    padding: 32px 16px;
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.outer {
  position: relative;
  z-index: 2;

  margin: auto;

  width: 100%;
  max-width: 448px;

  background: $light;
  border-radius: 15px;

  @include dark {
    background: $dark-medium;
    border: 2px solid $light-twenty-five;
  }
}

.container {
  padding: 1rem 1.75rem;
}

.close {
  position: absolute;
  top: 20px;
  left: 1.75rem;

  // width: 40px;
  // height: 40px;

  // background-color: $light;
  // border: 2px solid $lightgray;
  // border-radius: 50%;

  &.onRight {
    left: unset;
    right: 1.75rem;
  }

  svg {
    transition: transform 0.2s;
    fill: $dark-medium;
  }

  &:hover {
    svg {
      // transform: rotate(90deg);
      fill: $dark;
    }
  }

  @include dark {
    // background: $dark-medium;
    // border: 2px solid $neutrals4;
    // border-radius: 50%;

    svg {
      fill: $neutrals4;
    }

    &:hover {
      svg {
        fill: $light;
      }
    }
  }
}
