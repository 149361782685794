@import "../../styles/helpers";

.wrapper {
  flex-grow: 1;

  & > div {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}

.title {
  margin-bottom: 10px;

  font-size: 30px !important;
  @include heading-4;
}

.text {
  margin-bottom: 24px;
  @include body-14;
  @include dark {
    color: $neutrals6;
  }
}

.textActiveLink {
  color: $orange-kephi;
}

.variants {
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.btns {
  display: flex;

  @include m {
    display: block;
  }
}

@mixin button {
  padding: 0 24px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 48px;

  color: $white;

  border-radius: 24px;

  transition: all 0.2s;

  @include m {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 8px;

    @include m {
      margin: 0 0 8px;
    }
  }
}

.cancelButton {
  @include button;

  &:hover {
    background-color: darken($white, 10%);
  }
}

.confirmButton {
  @include button;

  &:hover {
    background-color: darken($orange-kephi, 10%);
  }
}
