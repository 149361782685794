// common styles
body {
  min-width: 375px;

  font-size: 14px;
  line-height: (24 / 14);
  color: $dark;
  background: $light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include SairaRegular;
}

html,
body {
  height: fit-content;
  min-height: 100vh;

  @include dark {
    background: $dark;
    background-color: $dark;
  }
}

#root {
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 100vh;

  @include dark {
    background: $dark;
    background-color: $dark;
  }
}

.content {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  background-image: url('../assets/img/full-background.svg');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none;

  color: black;
}

svg,
img {
  vertical-align: middle;
}

.desktop {
  &-hide {
    @include d {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include d {
      display: block !important;
    }
  }

  &-text-right {
    @include d {
      text-align: right;
    }
  }
}

.tablet {
  &-hide {
    @include t {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include t {
      display: block !important;
    }
  }
}

.mobile {
  &-hide {
    @include m {
      display: none !important;
    }
  }

  &-show {
    display: none !important;

    @include m {
      display: block !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.slick-track {
  min-width: 100% !important;
}
