@import "../../styles/helpers";

.top {
  margin-bottom: 48px;
  padding-bottom: 32px;

  display: flex;
  align-items: center;

  // @include dark {
  border-color: $neutrals3;
  border-bottom: 1px solid $neutrals6;

  @include t {
    margin-bottom: 32px;
    padding-bottom: 0;

    display: block;

    border: none;
  }

  @include m {
    margin-bottom: 24px;
  }
  // }
}

.title {
  color: $neutrals3;
  margin-right: auto;

  @include body-1;
  @include t {
    display: none;
  }
  @include dark {
    color: $neutrals6;
  }
}

.search {
  position: relative;

  margin-left: 30px;

  width: 320px;
  flex-shrink: 0;

  @include t {
    margin: 0;

    width: 100%;
  }
}

.input {
  padding: 0 48px 0 14px;

  width: 100%;
  height: 48px;

  color: $neutrals4;
  background: transparent;
  border: 1px solid $neutrals6;

  @include dark {
    border-color: $neutrals3;
  }

  transition: border-color 0.2s;

  letter-spacing: 0.5px;
  font-size: inherit;

  border-radius: 12px;

  @include poppins;
  @include caption-1;

  @include placeholder {
    color: $neutrals5;
  }

  &:focus {
    color: $neutrals3;
    &::placeholder{
      color: $neutrals4;
    }
    border-color: $orange-kephi;
    @include dark {
      color: $neutrals5;
    }
  }
  &:hover {
    border-color: $orange-kephi;
  }
}

.result {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;

  width: 32px;
  height: 32px;

  background: $orange-kephi;
  border-radius: 50%;

  transition: background 0.2s;

  svg {
    fill: $light;
  }

  &:hover {
    background: darken($orange-kephi, 10);
  }
}

.sorting {
  margin-bottom: 2rem;

  display: flex;
  align-items: center;

  @include t {
    display: block;
  }

  @include m {
    margin-bottom: 1.5rem;
  }

  .dropdown {
    margin-right: auto;
    color: $neutrals3;

    width: 256px;

    @include t {
      margin: 0 0 32px;

      width: 100%;
    }
  }
}

.nav {
  display: flex;

  @include m {
    width: 100%;

    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 5px;
    // justify-content: center;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      margin-top: 10px;
      padding-top: 10px;
      background-color: $neutrals5;
      border-radius: 10px;
    }

    &::before,
    &::after {
      content: "";
      // flex-shrink: 0;

      width: 32px;
      // height: 1px;
    }
  }
}

.row {
  display: flex;

  @include t {
    display: block;
  }
}

.wrapper {
  flex-grow: 1;

  padding-left: 32px;

  @include t {
    padding-left: 0;
  }
}

.filters {
  flex-shrink: 0;

  width: 256px;

  @include t {
    margin-bottom: 32px;

    width: 100%;
  }
}

.range {
  margin-bottom: 24px;

}

.scale {
  display: flex;
  justify-content: space-between;
  color: $neutrals3;

  @include dark {
    color: $light-fifty;
  }

  font-weight: 500;
}

.group {
  margin-bottom: 24px;
  padding: 24px 0;

  border-width: 1px 0;
  border-style: solid;
  border-color: $neutrals6;

  // @include dark {
  border-color: $neutrals6;
  // }
}

.item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.label {
  margin-bottom: 12px;

  @include hairline-2;
  color: $neutrals5;
}

.reset {
  display: inline-flex;
  align-items: center;
  color: $neutrals4;

  @include button-2;
  cursor: pointer;

  // transition: color 0.2s;

  svg {
    margin-right: 8px;
    fill: $orange-kephi;
    transition: fill 0.2s;

    @include dark {
      fill: $light;
    }
  }

  &:hover {
    color: $neutrals5;
    color: darken($orange-kephi, 10);
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}


.list {
  margin: (-32px) -16px 0;

  display: flex;
  flex-wrap: wrap;

  @include a {
    margin: 0;

    display: block;
  }
}

.card {
  margin: 2rem 1rem 0;
  padding: 12px;

  box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
  max-width: calc(33.333% - 32px);
  border-radius: 20px;
  flex: 0 0 calc(33.333% - 32px);

  @include r(970) {
    max-width: calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
  }

  @include a {
    margin: 0;

    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  text-align: center;
}

.checkbox {
  margin-bottom: 24px;
}

.loader {
  margin-top: .75rem;
  transform: scale(1.5);
}