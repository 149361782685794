@import "./colors.scss";
@import "./fonts.scss";
@import "./mediaQueries.scss";

// theme
@mixin dark {
  @at-root :global(.dark-mode) & {
    @content;
  }
}

@mixin dark-body {
  @at-root .dark-mode {
    @content;
  }
}

@mixin dark-common {
  @at-root .dark-mode & {
    @content;
  }
}

// animation
$animation-timing-default: 0.2s;

// typography
.text-gradient {
  display: inline-block;

  @include SairaRegular;

  background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  &::selection {
    -webkit-text-fill-color: $white;
  }

  ::selection {
    -webkit-text-fill-color: $white;
  }
}

// headings
@mixin heading-1 {
  font-size: 56px;
  font-weight: normal;
  font-style: normal;
  line-height: 85px;
}

@mixin heading-2 {
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  line-height: 73px;
}

@mixin heading-4 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 55px;
}

@mixin heading-6 {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  line-height: 36px;
}

// body
@mixin body-14 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 21px;
}
@mixin body-14-bold {
  @include body-14;
  font-weight: bold;
}

@mixin body-16 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
}

@mixin body-16-bold {
  @include body-16;
  font-weight: bold;
}

@mixin body-18 {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 27px;
}

@mixin body-20 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
}

@mixin body-1 {
  font-size: 24px;
  line-height: (32 / 24);
  letter-spacing: -0.01em;
}

@mixin body-bold-1 {
  font-size: 24px;
  font-weight: 600;
  line-height: (32 / 24);
  letter-spacing: -0.01em;
}

@mixin body-2 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: (24 / 16);
}

@mixin body-bold-2 {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: (24 / 16);
}

@mixin caption-1 {
  font-size: 14px;
  line-height: (24 / 14);
}

@mixin caption-bold-1 {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: (24 / 14);
}

@mixin caption-2 {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: (20 / 12);
}

@mixin caption-bold-2 {
  font-size: 12px;
  font-weight: 600;
  line-height: (20 / 12);
}

@mixin hairline-1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

@mixin hairline-2 {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}

@mixin button-1 {
  @include SairaRegular;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

@mixin button-2 {
  @include SairaRegular;
  font-size: 14px;
  font-weight: 700;
  line-height: (16 / 14);
}

//triangle
@mixin arr($width, $height, $bg, $direction) {
  width: 0;
  height: 0;

  border-style: solid;

  @if $direction==t {
    border-width: 0 ($width / 2 + px) ($height + px) ($width / 2 + px);
    border-color: transparent transparent $bg transparent;
  }

  @if $direction==r {
    border-width: ($height / 2 + px) 0 ($height / 2 + px) ($width + px);
    border-color: transparent transparent transparent $bg;
  }

  @if $direction==b {
    border-width: ($height + px) ($width / 2 + px) 0 ($width / 2 + px);
    border-color: $bg transparent transparent transparent;
  }

  @if $direction==l {
    border-width: ($height / 2 + px) ($width + px) ($height / 2 + px) 0;
    border-color: transparent $bg transparent transparent;
  }

  @if $direction==tl {
    border-width: ($height + px) ($width + px) 0 0;
    border-color: $bg transparent transparent transparent;
  }

  @if $direction==tr {
    border-width: 0 ($width + px) ($height + px) 0;
    border-color: transparent $bg transparent transparent;
  }

  @if $direction==br {
    border-width: 0 0 ($height + px) ($width + px);
    border-color: transparent transparent $bg transparent;
  }

  @if $direction==bl {
    border-width: ($width + px) 0 0 ($height + px);
    border-color: transparent transparent transparent $bg;
  }
}

// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)
// placeholder
@mixin placeholder {
  &::placeholder {
    @content;
  }
}

// media query width
@mixin r($width) {
  @media only screen and (max-width: $width + "px") {
    @content;
  }
}

@mixin rmin($width) {
  @media only screen and (min-width: $width + "px") {
    @content;
  }
}
