@import "../../../styles/helpers";

@mixin button-active-grey {
  color: $neutrals2;

  &:hover {
    color: $white;
  }
}

.formWrapper {
  display: flex;
  justify-content: space-between;
  @include r(1200) {
    flex-direction: column-reverse;
  }
}

$default-margin: 32px;

.section {
  @include x {
    padding-top: 80px;
  }

  @include m {
    padding-top: 64px;
  }
}

.section {
  &.section {
    background: $white;
  }
}

.container {
  display: flex;
  align-items: flex-start;

  @include t {
    display: block;
  }
}

.wrapper {
  padding-right: 128px;

  width: calc(100% - 352px);
  flex: 0 0 calc(100% - 352px);

  @include x {
    padding-right: 64px;
  }

  @include d {
    padding-right: $default-margin;

    width: calc(100% - 304px);
    flex: 0 0 calc(100% - 304px);
  }

  @include t {
    padding: 0;

    width: 100%;
  }
}

.form {
  position: relative;

  width: 100%;
  max-width: 640px;
}

.preview {
  width: 352px;

  @include d {
    width: 304px;
  }
  @include t {
    margin-bottom: 40px;

    width: fit-content;
  }

  @include m {
    width: 100%;
  }
}

.head {
  margin-bottom: 40px;

  display: flex;

  @include d {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @include m {
    margin-bottom: $default-margin;
  }

  .button {
    margin-left: 24px;
    flex-shrink: 0;

    @include d {
      margin: 0 0 16px;
    }

    &.switchCollectibleMode {
      @include button-active-grey;
    }
  }
}

.title {
  margin-right: auto;
}

.list {
  margin-bottom: 40px;
  padding-bottom: 40px;

  border-bottom: 1px solid $neutrals6;

  @include m {
    margin-bottom: $default-margin;
    padding-bottom: $default-margin;
  }

  @include dark {
    border-color: $neutrals3;
  }
}

.item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 40px;

    @include m {
      margin-bottom: $default-margin;
    }
  }
}

.note {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.file {
  position: relative;

  margin-top: 16px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 182px;

  background: $light;
  border-radius: 16px;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }

  @include dark {
    background: $neutrals2;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.icon {
  margin-bottom: 10px;

  width: 24px;
  height: 24px;

  svg {
    fill: $neutrals4;
  }
}

.format {
  @include caption-2;
  color: $neutrals4;
}

.category {
  @include body-bold-2;
  color: $neutrals2;
  @include dark {
    color: $neutrals6;
  }
}

.fieldset {
  margin-top: $default-margin;

  .field {
    input::placeholder {
      @include caption-bold-1;
      color: $neutrals4;
    }

    & + & {
      margin-bottom: $default-margin;

      @include m {
        margin-bottom: $default-margin / 1.6;
      }
    }

    textarea,
    input {
      color: $neutrals2;
      border-color: $neutrals6;

      &:focus {
        border-color: $neutrals4;
      }
      @include dark {
        color: $neutrals5;
        border-color: $neutrals3;
        &:focus {
          border-color: $neutrals6;
        }

      }
    }
  }
}

.label {
  margin-bottom: 12px !important;

  @include hairline-2;
  color: $neutrals5;
}

.royaltiesBox{
  display: flex;
  flex-direction: row;
}

.royaltiesLabel {
  @include hairline-2;
  color: $neutrals5;
}

.royaltiesTooltip {
  margin-top: -5px;
  margin-bottom: 6px;
}
.row {
  margin: 0 -10px;

  display: flex;

  @include m {
    margin: 0;

    display: block;
  }
}

.rowSmall {
  margin: 0 10px;

  display: flex;
  flex: 0 0 calc(66.666% - 20px);

  @include m {
    margin: 0;

    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.col {
  margin: 0 10px;

  width: calc(33.333% - 20px);
  flex: 0 0 calc(33.333% - 20px);

  @include m {
    margin: 0;

    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.colSmall {
  margin: 0 10px;

  width: calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);

  @include m {
    margin: 0;

    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.options {
  margin-bottom: 40px;

  @include m {
    margin-bottom: $default-margin;
  }
}

.option {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: $default-margin;
  }
}

.squares {
  margin-bottom: $default-margin;

  display: flex;
  gap: 18px;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 52px;
  height: 52px;

  background: $gradient;
  border-radius: 50%;
}

.hidden {
  visibility: hidden;

  svg {
    visibility: visible;
  }
}

.square {
  padding: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 142px;
  height: 142px;

  font-weight: 700;
  text-transform: uppercase;

  background: $purple2;
  border-radius: 24px;

  :hover {
    cursor: pointer;
  }
}

.box {
  flex-grow: 1;
}

.disabled {
  padding: 0;

  display: block;

  background: $dark-bg;
  border: 2px solid $white;
  opacity: 0.34;

  :hover {
    cursor: default;
  }
}

.header {
  padding: 8px 24px 9px 23px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(255, 255, 255, 0.34);
  border-radius: 22px 22px 0 0;
  gap: 5px;

  span {
    font-size: 12px;
    line-height: 12px;

    color: $white;
  }
}

.body {
  margin-top: 6px;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;

  span {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  // background: $dark-bg;
  border-radius: 22px;
  gap: 20px;

  span {
    width: 100%;
    max-width: 80px;

    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
}

.switch {
  flex-shrink: 0;

  margin-left: 24px;
}

.text {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.foot {
  display: flex;
  align-items: center;

  @include m {
    display: block;

    text-align: center;
  }

  .button {
    @include m {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 24px;

      @include m {
        margin: 0 0 12px;
      }
    }

    &.previewButton {
      @include button-active-grey;
    }
  }
}

.saving {
  margin-left: auto;

  display: inline-flex;
  align-items: center;

  color: $neutrals2;

  @include button-1;
}

.loader {
  margin-left: 12px;

  svg {
    fill: $neutrals2;
  }
}

.fieldDropdownSuggestion {
  color: $neutrals4;
  @include dark {
    color: $neutrals6;
  }
}

.fieldSuggestion {
  margin: 0 5px;

  color: $neutrals4;
  @include dark {
    color: $neutrals6;
    font-weight: bold;
  }
}

.recieve {
  padding: 1px 3px;

  font-weight: bold;

  color: $orange-kephi;
  border: 3px solid $orange-kephi;
  border-radius: 4px;
}

.serviceFee {
  margin: 0 3px;
}

.feeItem {
  display: flex;
  align-items: center;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
  @include dark {
    color: $neutrals5;
  }
}

.cryptocurrenciesDropdown {
  position: absolute;
  top: calc(50% + 12px);
  right: 20px;
  // background: red;
  width: 6rem;

  transform: translateY(-50%);

  &Head {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $orange-kephi;
  }

  &Selection {
    // @include hairline-2;
    margin-left: 5px;

    font-weight: bold;

    color: $orange-kephi;
  }

  &Arrow {
    padding-left: 10px;

    width: 20px;
    height: 100%;
  }
}

.cryptocurrenciesCalculations {
  margin-top: 20px;

  color: $neutrals4;

  &Price {
    display: inline-block;

    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;

    border-radius: 4px;
    flex-shrink: 0;
  }

  &Inner {
    padding: 0 6px;

    border: 2px solid $orange-kephi;
    border-radius: 3px;
  }

  &InnerText {
    color: $orange-kephi;
  }
}

.previewImg {
  img,
  video {
    width: 100%;
  }
}

.fee {
  display: flex;
  flex-direction: column;

  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.clear {
  position: absolute;
  top: 14px;
  right: 14px;

  cursor: pointer;
}

.btn {
  margin-top: 9px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  cursor: pointer;

  border: 2px solid transparent;
  border-radius: 12px;

  &_add {
    border-color: #45b36b;

    transition: all 0.2s ease;

    &:hover {
      background: #45b36b;

      transition: all 0.2s ease;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &_remove {
    background: $light;
    border-color: $neutrals6;

    transition: all 0.2s ease;

    &:hover {
      border-color: #ea5424;

      transition: all 0.2s ease;
    }
    @include dark {
      background: $dark;
      border-color: $neutrals3;
      &:hover {
        border-color: #ea5424;
        transition: all 0.2s ease;
      }
    }
  }
}

.btns {
  display: flex;
  gap: 10px;

  flex-direction: column;
}

.flexBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltipInfo {
  margin-left: .5rem;
  top: 12px;
  right: 18px;
  text-align: center;
  background-color: $neutrals6;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  line-height: 24px;
  cursor: default;
}

.tooltipInfo::before{
  content:'?';
  font-weight: bold;
  font-family: "Lucida Console", Courier, monospace;
  font-size: 16px;
  color: $neutrals4;
}

.tooltipInfo:hover p{
  display:block;
  transform-origin: 100% 0%;
}

.tooltipInfo p{
  display: none;
  margin: 0 auto;
  min-width: 380px;
  width: fit-content;
  max-width: 600px;
  z-index: 1;
  text-align: left;
  background-color: $light;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 1px $neutrals6;
  right: -32px;
  top: -30px;
  color: $dark;
  line-height: 1.4rem;
  white-space: nowrap;
  overflow-wrap: break-word;
  white-space: normal;
  @include dark {
    box-shadow: 0px 5px 5px 1px $neutrals3;
  }
}

.tooltipInfo p::after{
  width:100%;
  height:40px;
  content:'';
  position: absolute;
  top:-40px;
  left:0;
}

.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlayBox {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #666666; opacity: .8;
  z-index: 1000;
}

.loader {
  margin: 24px auto 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 58px;
  height: 58px;

  svg {
    width: 100%;
    height: 100%;
    fill: $neutrals4;
  }
}
