@import "../../../../styles/helpers";

$default-margin: 32px;

@mixin button {
  color: $neutrals2;
  box-shadow: 0 0 0 2px $neutrals6 inset;

  svg {
    fill: $neutrals4;
  }

  &:hover {
    background: $orange-kephi;
  }
}

.title {
  margin-bottom: $default-margin;
}

.info {
  margin-bottom: $default-margin;

  @include body-2;

  strong {
    font-weight: 600;
  }

  // @include a {
    overflow-wrap: break-word;
  // }
}

.row {
  padding-top: 12px;

  display: flex;

  &:nth-child(-n + 2) {
    padding: 12px 0;

    font-weight: 500;

    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }

    .col {
      color: $neutrals2;

      @include dark {
        color: $light;
      }
    }

    .colWithInput {
      width: 100%;
    }
  }
}

.amount {
  min-height: auto !important;
  input {
    padding: 0;

    width: 100%;
    height: 100%;

    font-size: 16px;

    background-color: transparent;
    border: none;
    border-radius: 0;
  }
}

.col {
  @include body-2;

  &:first-child {
    color: $orange-kephi;
  }

  &:nth-child(2) {
    margin-left: auto;
    padding-left: 30px;

    font-weight: 500;
  }
}

.btns {
  margin-top: $default-margin;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:last-child {
    @include button;
  }
}

.attention {
  margin-top: 32px;
  padding: 24px;

  display: flex;
  align-items: center;

  color: $pink;
  background: rgba($pink, 0.08);
  border-radius: 8px;

  @include m {
    align-items: stretch;
  }
}

.preview {
  margin-right: 16px;

  width: 32px;
  flex-shrink: 0;

  svg {
    fill: $pink;
  }
}

.details {
  flex-grow: 1;
}

.subtitle {
  @include body-bold-2;
}

.text {
  @include caption-2;
}

.line {
  display: flex;
  align-items: center;

  .text {
    color: $neutrals4;
  }
}

.icon {
  position: relative;

  margin-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background: $neutrals6;
  border: 2px solid $neutrals6;
  border-radius: 50%;

  transition: all 0.2s;
  flex-shrink: 0;

  svg {
    transition: fill 0.2s;
    fill: $neutrals4;
  }

  .loader {
    position: absolute;
    top: -3px;
    right: -3px;
    left: -3px;
    bottom: -3px;

    background: $light;
    border-left-color: $orange-kephi;

    @include dark {
      background: $dark;
    }
  }
}

.avatar {
  margin-left: 16px;

  width: 48px;
  height: 48px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.rubicModalContainer {
  padding: 0 !important;

  .modal[class*="ngcontent"] {
    background: $neutrals3 !important;
  }
}

.modal[class*="ngcontent"] {
  background: $neutrals3 !important;
}
.wertContainer {
  padding: 4rem 1.75rem 1rem;
}
.wertOuter {
  max-width: fit-content;
}
.errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 1rem 0 auto;
    padding: .25rem .75rem;
    border-radius: 1rem;
    color: $neutrals3;
    background-color: $red-light;

    @include dark {
      background-color: $red-gray;
      color: $light;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}