@import "../../styles/helpers";

.user {
  position: relative;
}

.active {
  z-index: 10;

  .head {
    .arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .body {
    visibility: visible;

    opacity: 1;

    transform: translateY(0);
  }
}

.head {
  position: relative;

  padding: 4px 16px 4px 4px;

  display: flex;
  align-items: center;

  cursor: pointer;

  border-radius: 20px;
  box-shadow: inset 0 0 0 1px $neutrals6;

  transition: box-shadow 0.2s;

  @include button-2;

  @include dark {
    box-shadow: inset 0 0 0 1px $neutrals3;
  }

  &:hover,
  &:active,
  &:focus-within {
    box-shadow: inset 0 0 0 1px $orange-kephi;
      @include dark {
        box-shadow: inset 0 0 0 1px $neutrals5;
        .arrow{
          svg {
            fill: $neutrals5;
          }
        }
      }
  }
}

.avatar {
  margin-right: 4px;

  width: 1.5rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
  }
}

.selection {
  @include body-14;
  margin-left: 0;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: $neutrals4;

  @include dark {
    color: $neutrals4;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 50%;

  transform: translateY(-50%);

  svg {
    fill: $orange-kephi;
    @include dark {
      fill: $neutrals4;
    }

    transition: transform $animation-timing-default;
  }

  @include m {
    right: 0;
  }
}

.body {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  left: 0;

  padding: .3rem 0;

  visibility: hidden;

  background: $light;
  border-radius: 12px;
  opacity: 0;
  box-shadow: inset 0 0 0 1px $neutrals6, 0 4px 12px rgba($neutrals2, 0.1);

  transition: all $animation-timing-default;
  transform: translateY(3px);

  @include dark {
    background: $dark;
    box-shadow: inset 0 0 0 1px $neutrals3, 0 4px 12px rgba($dark, 0.1);
  }

  span {
    white-space: nowrap;
  }
}

.option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .375rem .5rem;

  font-size: 14px;

  cursor: pointer;

  transition: color $animation-timing-default;
  -webkit-tap-highlight-color: $neutrals3;

  &:hover,
  &.selected {
    font-weight: bold;
    color: $neutrals4;
    @include dark{
      color: $neutrals5;
    }
  }

  @include m {
    padding: .25rem;
  }
}

.selectioned {
  font-weight: bold;
  color: $neutrals4;
}

.image {
  width: 1.5rem;
}

.text{
  margin-left: .325rem;
  color: $neutrals3;
  @include dark{
    color: $neutrals4;
    &:hover {
      color: $neutrals5;
    }
  }
}