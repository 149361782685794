@import "../../styles/helpers";

.loginButton {
  padding: 0 1.5rem !important;
  background: $gradient-gallery !important;

  img {
    display: block;
    margin-right: .5rem;
    margin-left: -.25rem;
  }

  &:hover {
    color: white;
    .loginText {
      font-size: .879rem;
      font-weight: bolder !important;
    }
  }
  @include m {
    padding: 0 !important;
    width: 40px;
    img {
      margin: 0;
    }
  }
}

.loginText {
  font-size: .875rem;
  @include m {
    display: none;
  }
}

.outerContainer {
  max-width: 42rem;
}
.modalContainer {
  padding: 1rem 1.75rem;
}