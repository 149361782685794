@import "../../styles/helpers.scss";

.note {
  display: inline-flex;
  font-size: .7rem;
  line-height: 1rem;
  color: $blue-light;

  .infoIcon {
    padding-right: .25rem;
    svg{
      fill: $blue-light;
    }
  }
}