@import "../../styles/helpers";

.title {
  margin-bottom: .75rem;
}

.text {
  margin-bottom: 1.5rem;

  @include body-2;
  color: $neutrals4;
}

.info {
  margin-bottom: 3px;

  @include body-bold-1;
  @include dark {
    color: $neutrals6;
  }
}

.input {
  width: 100%;
  height: 48px;
  min-height: auto !important;

  color: $neutrals2;
  background: none;

  border-color: $neutrals3;
  border-bottom: 1px solid $neutrals6;
  border-radius: 0;

  transform: border-color 0.2s;

  @include poppins;
  @include body-bold-2;

  @include dark {
    color: $neutrals6;
  }

  @include placeholder {
    color: $neutrals4;
  }

  &:focus {
    border-color: $neutrals4;
  }

  input {
    padding: 0;

    color: $neutrals2;
    border: none;
    @include poppins;
    @include body-bold-2;
    @include placeholder {
      color: $neutrals4;
    }
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlayBox {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #666666; opacity: .8;
  z-index: 1000;
}

.loader {
  margin: 24px auto 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 58px;
  height: 58px;

  svg {
    width: 100%;
    height: 100%;
    fill: $neutrals4;
  }
}
