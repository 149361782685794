@import "../../styles/helpers";

.profile {
  flex-grow: 1;
}

.head {
  position: relative;

  padding: 32px 0;

  display: flex;
  overflow: hidden;
  align-items: flex-end;

  height: 326px;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include t {
    padding-bottom: 64px;

    height: 260px;
  }

  @include m {
    padding-bottom: 56px;

    height: 230px;
  }

  .container {
    display: flex;
    justify-content: flex-end;

    @include t {
      justify-content: center;
    }
  }

  &.active {
    .btns {
      opacity: 0;
    }

    .file {
      visibility: visible;

      opacity: 1;
    }
  }
}

.btns {
  transition: opacity 0.2s;

  .button {
    box-shadow: inset 0 0rem 1.5rem $white;
    color: white;
    @include dark {
      box-shadow: inset 0 0rem 1.5rem $white;
      color: white;
    }
    @include m {
      padding: 0 10px;
      flex-grow: 1;
    }
    margin-top: 32px;


    svg {
      fill: white;
    }

    &:hover {
      background: $orange-kephi;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.button.savePhotoButton {
  @extend .button;
  background: $orange-kephi;
  box-shadow: unset;

  &:hover {
    background: darken($orange-kephi, 10);
  }
}

.file {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  text-align: center;

  cursor: pointer;

  color: $light;
  opacity: 0;

  transition: all 0.2s;

  @include m {
    padding-top: 40px;

    align-items: flex-start;
  }

  &_wrapper {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  input {
    cursor: pointer;
  }

  button {
    z-index: 11;
  }

  &::before,
  &::after {
    content: "";

    position: absolute;
  }

  &::before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba($dark, 0.6);
  }

  &::after {
    top: 8px;
    right: 8px;
    left: 8px;
    bottom: 8px;

    border: 2px dashed $neutrals6;
    border-radius: 12px;
  }

  input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    font-size: 1400px;

    opacity: 0;
  }

  svg {
    margin-bottom: 24px;
    fill: $light;

    @include m {
      margin-bottom: 12px;

      width: 24px;
      height: 24px;
    }
  }

  .button {
    position: absolute;
    right: 16px;
    bottom: 16px;

    @include t {
      bottom: 48px;
    }

    @include m {
      right: 50%;

      transform: translateX(50%);
    }
  }
}

.wrap {
  position: relative;
  z-index: 2;
}

.info {
  font-size: 24px;
  font-weight: 600;
  line-height: (32 / 24);

  @include m {
    font-size: 16px;
  }
}

.text {
  @include body-2;

  @include m {
    font-size: 12px;
  }
}

.body {
  position: relative;

  padding: 40px 0;

  @include t {
    padding-top: 0;
  }

  @include m {
    padding: 0 0 64px;
  }

  .container {
    display: flex;
    align-items: flex-start;

    @include t {
      flex-direction: column;
    }
  }
}

.user {
  margin-top: -193px;

  width: 256px;
  flex-shrink: 0;

  @include t {
    margin: -32px auto 48px;

    width: 80%;
  }

  @include a {
    width: 100%;
  }
}

.wrapper {
  padding-left: 64px;

  width: calc(100% - 256px);
  flex: 0 0 calc(100% - 256px);

  @include x {
    padding-left: 32px;
  }

  @include t {
    padding-left: 0;

    width: 100%;
  }
}

.nav {
  margin-bottom: 40px;

  display: flex;

  @include d {
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.load {
  margin-top: 30px;

  display: flex;
  justify-content: center;
}
.modalOuter{
  min-width: 42.688rem;

  @include m {
    width: fit-content;
    min-width: 0;
  }
}
.modalContainer{
  padding: 1.75rem 1.5rem;

  @include a {
    padding: 2rem;
  }

  @include s {
    padding: 1rem;
  }
}
