@import "../../../../../styles/helpers";

@mixin button-active-grey {
  color: $neutrals2;

  &:hover {
    color: $white;
  }
}

$default-margin: 2rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  @include t {
    display: block;
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;

  display: flex;
  align-items: center;
  color: $light;
}

.subtitle {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  color: $light-fifty;
}

.item {
  position: relative;
  width: 100%;
  height: min-content;

  &:not(:last-child) {
    margin-bottom: 40px;

    @include m {
      margin-bottom: $default-margin;
    }
  }
}

.note {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.file {
  position: relative;

  margin-top: 16px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 19.313rem;

  background: $light;
  border-radius: 16px;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }

  @include dark {
    background: $dark-light;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.icon {
  margin-bottom: 10px;

  width: 24px;
  height: 24px;

  svg {
    fill: $neutrals4;
  }
}

.format {
  @include caption-2;
  color: $neutrals4;
}

.category {
  @include body-bold-2;
  color: $neutrals2;
  @include dark {
    color: $neutrals6;
  }
}

.previewImg {
  img,
  video {
    width: 100%;
  }
}

.clear {
  position: absolute;
  top: 14px;
  right: 14px;

  cursor: pointer;
}

.buttonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.375rem;
  width: 100%;
}

.backButton {
  font-weight: 700 !important;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
}
.nextbutton {
  font-weight: 700;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
  background: $gradient-gallery;
}

// Tooltip Info
.tooltipInfo {
  margin-left: .5rem;
  top: 12px;
  right: 18px;
  text-align: center;
  background-color: $neutrals6;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  line-height: 24px;
  cursor: default;
}

.tooltipInfo::before{
  content:'?';
  font-weight: bold;
  font-family: "Lucida Console", Courier, monospace;
  font-size: 16px;
  color: $neutrals4;
}

.tooltipInfo:hover p{
  display:block;
  transform-origin: 100% 0%;
}

.tooltipInfo p{
  display: none;
  margin: 0 auto;
  min-width: 380px;
  width: fit-content;
  max-width: 600px;
  z-index: 1;
  text-align: left;
  background-color: $light;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 1px $neutrals6;
  right: -32px;
  top: -30px;
  color: $dark;
  line-height: 1.4rem;
  white-space: nowrap;
  overflow-wrap: break-word;
  white-space: normal;
  @include dark {
    box-shadow: 0px 5px 5px 1px $neutrals3;
  }
}

.tooltipInfo p::after{
  width:100%;
  height:40px;
  content:'';
  position: absolute;
  top:-40px;
  left:0;
}

.errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 auto;
    padding: .25rem .75rem;
    border-radius: 1rem;
    color: $neutrals3;
    background-color: $red-light;

    @include dark {
      background-color: $red-gray;
      color: $light;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.controls {
  height: 3.35rem;
  width: 100%;
}