@import "../../styles/helpers";

@mixin button {
  color: $neutrals2;
  box-shadow: 0 0 0 2px $neutrals6 inset;

  svg {
    fill: $neutrals4;
  }

  &:hover {
    background: $orange-kephi;
  }
}

.title {
  margin-bottom: 32px;
}

.info {
  margin-bottom: 32px;

  @include body-2;

  strong {
    font-weight: 500;
  }
}

.stage {
  margin-bottom: 16px;

  @include body-bold-1;
}

.row {
  padding-top: 12px;

  display: flex;

  &:first-child {
    padding: 0 0 12px;

    font-weight: 500;

    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }
  }
}

.col {
  @include body-2;

  &:nth-child(2) {
    margin-left: auto;
    padding-left: 1rem;

    font-weight: 500;
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:last-child {
    @include button;
  }
}

.input_row {
  padding-top: 12px;
  min-height: auto !important;

  display: flex;
  align-items: center;

  font-weight: 500;

  border-bottom: 1px solid $neutrals6;
  @include dark {
    border-color: $neutrals4;
  }
}

.input {
  width: 100%;
  min-height: auto !important;

  input {
    padding: 0;

    font-size: 16px;
    line-height: 24px;

    border: none;
    border-radius: 0;

    @include dark {
      color: $neutrals6;
    }
  }
}
