@import "../../styles/helpers";

$defaultMargin: 32px;
$bigMargin: $defaultMargin * 2;
$smallMargin: $defaultMargin / 2;
$bgColor: $white;

@mixin button {
  color: $neutrals2;
  background: $bgColor;
  box-shadow: 0 0 0 2px $neutrals6 inset;

  svg {
    fill: $neutrals4;
  }

  &:hover {
    background: $orange-kephi;
  }
}

// .page {
//   .container {
//     max-width: 741px + 80px * 2;
//   }
// }
.top {
  margin-bottom: 64px;

  @include m {
    margin-bottom: $defaultMargin;
    padding-bottom: $smallMargin;

    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }
  }
}

.title {
  margin-bottom: $smallMargin;
  @include heading-2;
  @include dark {
    color: $light;
  }
}

.info {
  color: $neutrals4;
}

.row {
  margin: 0 - ($smallMargin);

  display: flex;

  @include d {
    margin: 0;

    display: block;
  }
}

.col {
  flex: 0 0 calc(50% - #{$smallMargin * 2});

  margin: 0 $smallMargin;

  @include d {
    margin: 0;

    width: 100%;
  }

  &:not(:last-child) {
    @include d {
      margin-bottom: $bigMargin;
    }

    @include m {
      margin-bottom: $defaultMargin;
      padding-bottom: $defaultMargin;

      border-bottom: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }
    }
  }
}

.user {
  display: flex;

  @include d {
    max-width: 416px;
  }
}

.avatar {
  margin-right: $defaultMargin;

  width: 128px;
  height: 128px;
  flex-shrink: 0;

  @include m {
    margin-right: $smallMargin;

    width: 64px;
    height: 64px;
  }

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.details {
  flex-grow: 1;
}

.stage {
  margin-bottom: 8px;
  @include body-bold-2;
}

.text {
  margin-bottom: $smallMargin;

  @include caption-2;
  color: $neutrals4;
}

.fileUpload {
  position: relative;

  display: inline-block;

  .button {
    @include button;
    background: unset;
  }

  &:hover {
    .button {
      color: $light;
      background: $orange-kephi;
      box-shadow: none;
    }
  }
}

.upload {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  font-size: 0;

  cursor: pointer;

  opacity: 0;
}

.list {
  margin-bottom: 40px;

  @include d {
    margin: 0 -20px $bigMargin;

    display: flex;
  }

  @include m {
    margin: 0 0 $defaultMargin;

    display: block;
  }
}

.field {
  input,
  textarea {
    color: $dark;
    background-color: $bgColor;
    border-color: $neutrals6;
  }
}

.item {
  @include d {
    flex: 0 0 calc(50% - 40px);

    margin: 0 20px;
  }

  @include m {
    margin: 0;

    width: 100%;
  }

  & > .button {
    @include button;
    margin-top: $defaultMargin;

    color: $neutrals4;
  }

  &:not(:last-child) {
    margin-bottom: 40px;

    @include d {
      margin-bottom: 0;
    }

    @include m {
      margin-bottom: $defaultMargin;
    }
  }
}

.category {
  margin-bottom: $defaultMargin;

  @include body-bold-2;
  color: $neutrals2;
}

.fieldset {
  & > .field {
    &:not(:last-child) {
      margin-bottom: $defaultMargin;
    }
  }
}

.box {
  position: relative;

  .button {
    position: absolute;
    right: 12px;
    bottom: 8px;

    padding: 0 10px;

    height: 32px;
    @include button;
  }
}

.note {
  color: $neutrals4;
}

.btns {
  margin-top: 40px;
  padding-top: 40px;

  display: flex;

  border-top: 1px solid $neutrals6;

  @include m {
    display: block;

    text-align: center;
  }

  @include dark {
    border-color: $neutrals3;
  }

  .button {
    @include m {
      width: 100%;
    }
    background: $orange-kephi;

    &:not(:last-child) {
      @include m {
        margin: 0 0 $defaultMargin;
      }
      margin-right: $defaultMargin;
    }

    &:hover {
      background: darken($orange-kephi, 10);
    }
  }
}

.clear {
  @include button-1;
  display: inline-flex;
  align-items: center;

  color: $neutrals4;

  transition: color $animation-timing-default;

  svg {
    margin-right: 8px;
    fill: $neutrals4;

    transition: fill $animation-timing-default;
  }

  &:hover {
    color: $orange-kephi;

    svg {
      fill: $orange-kephi;
    }
  }
}
