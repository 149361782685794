@import "../../../../styles/helpers";

.category {
  @include body-bold-2;
  @include dark {
    color: $neutrals6;
  }
}

.text {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.cards {
  margin: 24px -4px 0;

  display: flex;
  overflow-x: auto;

  min-height: 50px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  @include m {
    margin: 24px -32px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include m {
    &::before,
    &::after {
      content: "";

      width: 32px;
      height: 1px;
      flex-shrink: 0;
    }
  }
}

.card {
  margin: 0 4px;
  padding: 24px;

  width: 160px;

  cursor: pointer;

  background: $light;
  border: 2px solid $neutrals6;
  border-radius: 16px;
  flex-shrink: 0;

  @include button-2;

  @include m {
    margin: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @include dark {
    background: $neutrals2;
    border: 2px solid $neutrals3;
    color: $neutrals4;
  }
  &:hover {
    border: 2px solid $neutrals5;
    .plus {
      svg {
        opacity: 1;
      }
    }
  }
}

.plus {
  margin-bottom: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 31px;

  border-radius: 50%;
  background: $neutrals6;
  @include dark {
    background: $neutrals3;
  }

  svg {
    opacity: 0.6;

    transition: opacity 0.2s;
    fill: $light;
  }
}

.active {
  border-color: $orange-kephi;
  @include dark {
    border-color: lighten($orange-kephi, 5);
  }
  &:hover {
    border-color: darken($orange-kephi, 10);
  }
}

.load {
  margin-top: 10px;
  margin-left: calc(50% - 25px);
}
