@import "../../styles/helpers";

.loader,
.loaderWhite {
  border: 0.25rem solid #f3f3f3;
  border-radius: 50%;
  border-top: .25rem solid $orange-kephi;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader {
  margin: 0 auto;

  @include dark {
    animation-name: spin;
  }
}

.loaderWhite {
  animation-name: spin;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}