@import "../../../styles/helpers";

.back {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: $neutrals2;

  svg {
    margin-right: 24px;

    transition: transform 0.2s;
    fill: $neutrals2;

    @include m {
      flex-shrink: 0;
    }
  }

  &:hover {
    svg {
      transform: translateX(-2px);
    }
  }
}

.stage {
  color: $neutrals2;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 2.25rem;
  letter-spacing: 0em;

  @include m {
    font-size: 2rem;
  }

  @include dark {
    color: $light;

  }
}

.contentWrapper {
  padding-top: 1.5rem;
  overflow: visible;
  justify-content: center;
  width: 100%;
}

.description {
  @include body-18;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  padding-bottom: 1rem;
  text-align: center;

  @include dark {
    color: $light-fifty;
  }

  @include m {
    font-size: 1rem;
  }
}

.textActiveLink {
  color: $orange-kephi;

  transition: color 0.2s;

  &:hover {
    color: darken($orange-kephi, 20%);
  }
}

.menu {
  padding: 1.25rem 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-grow: 1;
  flex-shrink: 0;
  gap: 1.875rem;
  overflow: visible;

  @include t {
    gap: 1.25rem;
    // padding-left: 60px;
    // padding-right: 60px;
  }
  @include m {
    gap: 1rem;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    justify-content: flex-start;
  }
}
.menuItemText {
  // padding: 0rem 0 2rem 0;

  box-sizing: border-box;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;

  color: $dark;
  border-radius: 0 0 24px 24px;

  @include dark {
    color: $light;
  }
}
.menuItemDescription {
  padding: .25rem .75rem 0 .75rem;
  font-size: 0.625rem;
  line-height: .9rem;
  max-width: 19rem;
  text-align: center;

  color: $light-fifty;

  @include d {
    max-width: 17rem;
  }

  @include t {
    max-width: 16rem;
  }

  @include s {
    max-width: 14rem;
  }
}
.linkContainer {
  width: calc(33.333%);
  max-width: 15.417rem;

  @include a {
    width: auto;
    width: 12.25rem;
  }
}
.link {
  border-radius: 10px;
  border: 1px solid $light-fifty;
  background: $light;
  -moz-box-shadow: 0 1rem 2rem 0 $neutrals6;
  -webkit-box-shadow: 0 1rem 2rem 0 $neutrals6;
  box-shadow: 0 1rem 2rem 0 $neutrals6;
  height: 9.375rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
  // gap: .5rem;

  @include t {
    padding: .25rem auto 0rem auto;
  }

  &:active {
    box-shadow: inset 0 0 0 2px $neutrals3;

    @include t {
      box-shadow: none;
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals4;

      @include t {
        box-shadow: none;
      }
    }

    .icon {
      &::before {
        opacity: 1;
      }

      svg {
        &:first-child {
          opacity: 0.5;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }
    }

    .menuItemText {
      opacity: .5;
    }
  }

  &:hover{
    box-shadow: inset 0 0 0 1px $neutrals6;
    @include dark {
      box-shadow: none;
      border: 2px solid $light;
    }
  }

  @include dark {
    background: transparent;
    box-shadow: none;
    border: 2px solid $light-fifty;
  }

  @include a {
    height: 10.25rem;
    justify-content: center;
    width: 12.25rem;
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5rem;
  height: 5rem;

  @include m {
    // width: 260px;
    // height: 170px;
  }

  @include s {
    // width: 240px;
    // height: 150px;
  }
}
.childContainer {
  padding: 3rem 0 0;

  @include d {
    padding: 1rem 0 0;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  transition: 1s;
  z-index: 200;
  height: 100%;
  gap: 1.875rem;
  padding: 1.25rem 0;
  align-items: center;
  justify-content: space-between;

  @include t {
    gap: 1.25rem;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    justify-content: flex-start;
  }
  @include m {
    gap: 1rem;
  }
}

.tabElement {
  width: fit-content;
}
.tabTitle {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  color: $neutrals5;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;

  @include m {
    font-size: 1em;
  }
  @include a {
    padding: .5rem;
  }
  @include s {
    padding: .325rem;
  }

  @include dark {
    color: $light;
  }
}
.tabIcon {
  display: contents;
  height: 1.25rem;
  width: 1.25rem;

  svg {
    height: 1.5rem;
    width: 1.25rem;
    // margin-left: 0 !important;
    // margin-right: .5rem !important;
    margin: 0 !important;
  }
}
.tabLine{
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
}
.new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  gap: 10px;
  background: linear-gradient(90deg, #C24075 0%, #FF8438 100%);
  border-radius: 100px;

  font-weight: 400;
  font-size: .688rem;
  line-height: .688rem;
  color: $light;
}

.chainButton {
  box-sizing: border-box;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);
  padding: .5rem auto;
  width: calc(33.333%);

  @include a {
    width: auto;
  }

  &:hover {
    // font-weight: 700;
    background: transparent;
    border-color: $light;
    img {
      transition: .15s;
      filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
    }
  }

  &:hover, &.active {
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 5.625rem;
      padding: 2px;
      margin: -2px;
      background: $gradient-gallery;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }
  }
}

.disclaimer {
  color: $dark;
  @include dark {
    color: $neutrals4;
  }
}

.animate {
  animation-duration: 0.9s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
  animation-fill-mode: backwards;
}

/* Fade In */
.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
}
@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Pop In */
.animate.pop {
  animation-name: animate-pop;
}
@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Blur In */
.animate.blur {
  animation-name: animate-blur;
  animation-timing-function: ease;
}
@keyframes animate-blur {
  0% {
    opacity: 0;
    filter: blur(15px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

/* Grow In */
.animate.grow {
  animation-name: animate-grow;
}
@keyframes animate-grow {
  0% {
    opacity: 0;
    transform: scale(1, 0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Splat In */
.animate.splat {
animation-name: animate-splat;
}
@keyframes animate-splat {
0% {
opacity: 0;
transform: scale(0, 0) rotate(20deg) translate(0, -30px);
}
70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
}

85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
}

100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
}
}

/* Roll In */
.animate.roll {
animation-name: animate-roll;
}
@keyframes animate-roll {
0% {
opacity: 0;
transform: scale(0, 0) rotate(360deg);
}
100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0deg);
}
}

/* Flip In */
.animate.flip {
animation-name: animate-flip;
transform-style: preserve-3d;
perspective: 1000px;
}
@keyframes animate-flip {
0% {
opacity: 0;
transform: rotateX(-120deg) scale(0.9,0.9);
}
100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1,1);
}
}

/* Spin In */
.animate.spin {
animation-name: animate-spin;
transform-style: preserve-3d;
perspective: 1000px;
}
@keyframes animate-spin {
0% {
opacity: 0;
transform: rotateY(-120deg) scale(0.9,0.9);
}
100% {
    opacity: 1;
    transform: rotateY(0deg) scale(1,1);
}
}

/* Slide In */
.animate.slide { animation-name: animate-slide; }
@keyframes animate-slide {
0% {
opacity: 0;
transform: translate(0,20px);
}
100% {
    opacity: 1;
    transform: translate(0,0);
}
}

/* Drop In */
.animate.drop {
animation-name: animate-drop;
animation-timing-function: cubic-bezier(.77,.14,.91,1.25);
}
@keyframes animate-drop {
0% {
opacity: 0;
transform: translate(0,-300px) scale(0.9, 1.1);
}
95% {
    opacity: 1;
    transform: translate(0,0) scale(0.9, 1.1);
}
96% {
    opacity: 1;
    transform: translate(10px,0) scale(1.2, 0.9);
}
97% {
    opacity: 1;
    transform: translate(-10px,0) scale(1.2, 0.9);
}
98% {
    opacity: 1;
    transform: translate(5px,0) scale(1.1, 0.9);
}
99% {
    opacity: 1;
    transform: translate(-5px,0) scale(1.1, 0.9);
}
100% {
    opacity: 1;
    transform: translate(0,0) scale(1, 1);
}
}

@media screen and (prefers-reduced-motion: reduce) {
  .animate {
    animation: none !important;
  }
}

.titleContainer {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 90%;
  letter-spacing: 0em;
  text-align: center;
  color: $light;
  padding-bottom: 1rem;
  border-bottom: 1px solid $light-twenty-five;
}