@import "../../../styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: .375rem;
  padding: 1.5rem;
  height: fit-content;

  border: 2px solid $lightgray;
  border-radius: 10px;
  box-shadow: 0 64px 64px -24px rgba(31, 47, 70, 0.12);

  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);

  @include m {
    padding: 1.125rem 1rem;
  }

  @include dark {
    border-color: $neutrals3;
  }
}

.status {
  @include SairaRegular;
  font-weight: 600;
  font-size: 1rem;
  color: $light;

  padding-top: .5rem;
}
.description {
  text-align: center;
  @include SairaRegular;
  font-weight: 400;
  font-size: .875rem;
  color: $light-fifty;
  border-bottom: 1px solid $neutrals6;
  width: 100%;
  padding-bottom: .75rem;

  @include dark {
    border-color: $light-twenty-five;
  }
}
.reloadLabel {
  @include SairaRegular;
  font-weight: 400;
  font-size: .875rem;
  color: $light-twenty-five;
}