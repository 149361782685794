@import "../../../styles/helpers";

.back {
  display: inline-flex;
  align-items: center;

  color: $neutrals2;

  svg {
    margin-right: 24px;

    transition: transform 0.2s;
    fill: $neutrals2;

    @include m {
      flex-shrink: 0;
    }
  }

  &:hover {
    svg {
      transform: translateX(-2px);
    }
  }
}

.stage {
  color: $neutrals2;

  @include m {
    font-size: 24px;
  }
}

.contentWrapper {
  padding-top: 27px;
  overflow:visible;
}

.description {
  @include body-18;
}

.textActiveLink {
  color: $orange-kephi;

  transition: color 0.2s;

  &:hover {
    color: darken($orange-kephi, 20%);
  }
}

.menu {
  padding: 4rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: auto;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 3.063rem;
  overflow:visible;

  @include d {
    margin: 0 auto;
    flex-direction: column;
    padding: 2rem 0;
  }

  @include s {
    gap: 2rem;
  }
}
.menuItemText {
  padding: 0rem 0 2rem 0;

  box-sizing: border-box;

  font-size: 1.5rem;
  line-height: 28px;

  color: $black;
  border-radius: 0 0 24px 24px;

  @include m {
    font-size: 1.25rem;
  }
}
.menuItemDescription {
  padding: .25rem .75rem 0 .75rem;
  font-size: 0.625rem;
  line-height: .9rem;
  max-width: 19rem;
  text-align: center;

  color: $neutrals4;

  @include d {
    max-width: 17rem;
  }

  @include t {
    max-width: 16rem;
  }

  @include s {
    max-width: 14rem;
  }
}
.linkContainer {
  overflow:visible;
}
.link {
  border-radius: 24px;
  border: 1px solid $neutrals6;
  background: $light;
  -moz-box-shadow: 0 1rem 2rem 0 $neutrals6;
  -webkit-box-shadow: 0 1rem 2rem 0 $neutrals6;
  box-shadow: 0 1rem 2rem 0 $neutrals6;

  @include t {
    padding: .25rem auto 0rem auto;
  }

  &:active {
    box-shadow: inset 0 0 0 2px $neutrals3;

    @include t {
      box-shadow: none;
    }

    @include dark {
      box-shadow: inset 0 0 0 2px $neutrals3;

      @include t {
        box-shadow: none;
      }
    }

    .icon {
      &::before {
        opacity: 1;
      }

      svg {
        &:first-child {
          opacity: 0.5;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }
    }

    .menuItemText {
      opacity: .5;
    }
  }

  &:hover{
    box-shadow: inset 0 0 0 1px $neutrals6;
  }

  &:disabled{
    .menuItemText {
      opacity: .5;
    }
    .icon {
      svg {
        &:first-child {
          opacity: 0.5;
        }
      }
    }
    &:hover{
      box-shadow: none;
    }
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 270px;
  height: 180px;

  @include m {
    width: 260px;
    height: 170px;
  }

  @include s {
    width: 240px;
    height: 150px;
  }
}

.animate {
  animation-duration: 0.9s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
  animation-fill-mode: backwards;
}

/* Fade In */
.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
}
@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.comingSoon{
  font-size: .9rem;
  margin-bottom: -1.551rem;
  margin-top: -.2rem;
}