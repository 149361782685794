@import "../../../../../styles/helpers";

@mixin button-active-grey {
  color: $neutrals2;

  &:hover {
    color: $white;
  }
}

$default-margin: 2rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  @include t {
    display: block;
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;

  display: flex;
  align-items: center;
  color: $light;
  margin-bottom: .75rem;
}

.option {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 0;
}
.box {
  flex-grow: 1;
}
.flexBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  position: relative;
  width: 100%;
}

.categoryContainer {
  min-height: 7.75rem;

  .errorContainer {
    clear: both;
    color: $dark;
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 0;
    min-height: 0;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }
  .errorLabel {
    font-size: .85rem;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: -.25rem;

    color: $red-dark;
  }
}

.note {
  margin-top: 4px;

  @include caption-2;
  color: $neutrals4;
}

.file {
  position: relative;

  margin-top: 16px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 19.313rem;

  background: $light;
  border-radius: 16px;

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }

  @include dark {
    background: $dark-light;
  }
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 400px;

  opacity: 0;
}

.icon {
  margin-bottom: 10px;

  width: 24px;
  height: 24px;

  svg {
    fill: $neutrals4;
  }
}

.format {
  @include caption-2;
  color: $neutrals4;
}

.category {
  font-weight: 600;
  font-size: 1rem;
  line-height: .75rem;

  color: $neutrals2;
  @include dark {
    color: $light;
  }
}

.switchInput {
  &:checked + .switchInner .switchBox {
    background: $dark-light;

    &::before {
      background: $gradient-gallery;
      transform: translate(22px, -50%);
    }
  }

}
.switchBox {
  background: $dark-light;
  border: none !important;
  height: 20px;

  &::before {
    height: 17px;
    width: 22px;
    left: 2px;
    border-radius: 100px;

    background: $light;
  }
}
.text {
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: $light-fifty;
}
.rowModalityButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .modalityButton {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0em;

    border-radius: .625rem;
    width: 50%;

    &.selected {
      background-color: transparent;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: .625rem;
        padding: 2px;
        margin: -2px;
        background: $gradient-gallery;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
}
$default-margin: 32px;
.fieldset {
  width: 100%;
  margin-top: $default-margin;

  input::placeholder {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}

.cryptocurrenciesDropdown {
  position: absolute;
  top: calc(50%);
  right: .75rem;
  // background: red;
  width: 6rem;

  transform: translateY(-50%);

  &Head {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $orange-kephi !important;
  }

  &Selection {
    // @include hairline-2;
    margin-left: 5px;

    font-weight: bold;

    color: $orange-kephi !important;
  }

  &Arrow {
    padding-left: 10px;
    right: .5rem;
    width: 20px;
    height: 100%;

    svg{
      fill: $orange-kephi !important;
    }
  }
}

.cryptocurrenciesCalculations {
  margin-top: 20px;

  color: $neutrals4;

  &Price {
    display: inline-block;

    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;

    border-radius: 4px;
    flex-shrink: 0;
  }

  &Inner {
    padding: 0 6px;

    border: 2px solid $orange-kephi;
    border-radius: 3px;
  }

  &InnerText {
    color: $orange-kephi;
  }
}

.label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: .75rem;

  color: $neutrals5;
  @include dark {
    color: $light;
  }
}
.buttonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.375rem;
  width: 100%;
  padding-top: 2rem;
}
.backButton {
  font-weight: 700 !important;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
}
.nextbutton {
  font-weight: 700;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  width: 100%;
  background: $gradient-gallery;
}

.tooltipInfo {
  margin-left: .5rem;
  top: 12px;
  right: 18px;
  text-align: center;
  background-color: $neutrals6;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  line-height: 24px;
  cursor: default;
}

.tooltipInfo::before{
  content:'?';
  font-weight: bold;
  // font-family: "Lucida Console", Courier, monospace;
  font-size: .875rem;
  color: $neutrals4;
}

.tooltipInfo:hover p{
  display:block;
  transform-origin: 100% 0%;
}

.tooltipInfo p{
  display: none;
  margin: 0 auto;
  min-width: 380px;
  width: fit-content;
  max-width: 600px;
  z-index: 1;
  text-align: left;
  background-color: $light;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 5px 5px 1px $neutrals6;
  right: -32px;
  top: -30px;
  color: $dark;
  line-height: 1.4rem;
  white-space: nowrap;
  overflow-wrap: break-word;
  white-space: normal;
  @include dark {
    box-shadow: 0px 5px 5px 1px $neutrals3;
  }
}

.tooltipInfo p::after{
  width:100%;
  height:40px;
  content:'';
  position: absolute;
  top:-40px;
  left:0;
}

.fee {
  display: flex;
  flex-direction: column;

  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}
.feeItem {
  display: flex;
  align-items: center;
  align-items: center;

  & + & {
    margin-top: .25rem;
  }
  @include dark {
    color: $neutrals5;
  }
}
.fieldSuggestion {
  margin: 0 5px;

  color: $neutrals4;
  @include dark {
    color: $neutrals6;
    font-weight: bold;
  }
}
.serviceFee {
  margin: 0 3px;
}
.recieve {
  padding: 1px 3px;
}
