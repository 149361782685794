@import "../../styles/helpers";

.title {
  margin-bottom: 32px;
}

.text {
  margin-bottom: 32px;

  @include body-2;
  color: $neutrals4;
}

.info {
  margin-bottom: 3px;

  @include body-bold-1;
}

.transfer {
  .input {
    min-height: auto !important;
    input {
      padding: 0;
      min-height: auto !important;

      width: 100%;
      height: 48px;

      color: $dark;
      background: none;

      border: none;

      // @include dark {
      border-color: $neutrals3;
      border-bottom: 1px solid $neutrals6;
      border-radius: 0;

      transform: border-color 0.2s;

      @include poppins;
      @include body-bold-2;
      // }

      @include placeholder {
        color: $neutrals4;
      }

      &:focus {
        border-color: $neutrals4;
      }
    }
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
