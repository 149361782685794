@import "../../../../styles/helpers";

.title {
  margin-bottom: 32px;
}

.line {
  margin-bottom: 32px;

  display: flex;

  strong {
    font-weight: 500;
    white-space: nowrap;
  }
}

.icon {
  position: relative;

  margin-right: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  background: $orange-kephi;
  border: 2px solid $orange-kephi;
  border-radius: 50%;

  transition: all 0.2s;
  flex-shrink: 0;

  svg {
    transition: fill 0.2s;
    fill: $light;
  }

  .loader {
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
  }
}

.details {
  flex-grow: 1;
}

.info {
  @include body-bold-2;
}

.text {
  color: $neutrals4;
  @include dark {
    color: $neutrals5;
  }
}

.switch {
  flex-shrink: 0;

  margin-left: 16px;
}

.row {
  padding-top: 12px;

  display: flex;
  align-items: center;

  &:first-child {
    font-weight: 500;

    border-color: $neutrals3;
    border-bottom: 1px solid $neutrals6;
    @include dark {
      border-color: $neutrals4;
    }
  }

  .input {
    width: 100%;
    min-height: auto !important;

    input {
      padding: 0;

      font-size: 16px;
      line-height: 24px;

      border: none;
      border-radius: 0;

      @include dark {
        color: $neutrals6;
      }
    }
  }
}

.col {
  @include body-2;

  &:nth-child(2) {
    margin-left: auto;
    padding-left: 1rem;

    font-weight: 500;
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
