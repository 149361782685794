@import "../../../styles/helpers";

.section {
  position: relative;
  padding: 4.75rem 0 4rem;

  &::before {
    content: "";
    background: $gradient-gallery;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.4;
  }

  @include a {
    padding: 4rem 0 3.5rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  position: relative;

  @include a {
    font-size: 3rem;
    line-height: 3rem;
    gap: 2rem;
  }
}

.title {
  @include SairaRegular;
  font-weight: 300;
  font-size: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  letter-spacing: -0.01em;
  color: $light;

  @include dark {
    color: $light;
  }

  @include a {
    font-size: 2.75rem;
    line-height: 2.75rem;
  }
}

.button {
  width: fit-content;
  padding: 1.5rem 2rem;
  margin-top: .5rem;
  font-weight: 700 !important;

  border: 1px solid transparent !important;
  -webkit-mask:
  linear-gradient($dark 0 0) content-box,
  linear-gradient($dark 0 0);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 5.625rem;
    padding: 1px;
    margin: -1px;
    background: $gradient-gallery;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  &:hover {
    color: $light;
  }
}