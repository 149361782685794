@import '../../../../styles/helpers.scss';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 1rem;
  min-height: 9.25rem;

  width: calc(33.333%);
  max-width: 11.75rem;

  box-shadow: inset 0 0 0 2px $light-fifty;
  border-radius: .625rem;

  &:hover {
    box-shadow: inset 0 0 0 2px $light;

    img {
      fill: $light;
    }
  }

  @include a {
    min-width: 8rem;
  }
}

.label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;
  color: $light;
}