@import "../../../styles/helpers";


.list {
  max-height: 19rem;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: inherit;
  }
  &::-webkit-scrollbar:vertical {
    width: 0.313rem;
  }
  &::-webkit-scrollbar-thumb {
    padding-left: 10px;
    background-color: $neutrals4 !important;
    background: $neutrals4 !important;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    @include dark {
      background-color: transparent !important;
      background: transparent !important;
    }
  }

  :last-child {
    .item, .div {
      border-bottom: none !important;
    }
  }
}
.item {
  padding-bottom: 10px;
  padding: 10px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $neutrals6;

  @include dark {
    border-color: $neutrals3;
  }

  &_link {

    display: block;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  & + & {
    margin-top: 10px;
  }
}

.uniqueItem {
  border-bottom: none;
  padding-bottom: 2rem;
}

.noBorderBottom {
  border-bottom: none;
}

.avatar {
  position: relative;

  margin-right: 1rem;

  width: 48px;
  height: 48px;
  flex-shrink: 0;

  &Img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }

  @include m {
    margin-right: .75rem;
  }
}

.reward {
  position: absolute;
  right: -5px;
  bottom: -5px;

  width: 26px;
  height: 28px;

  img, svg {
    position: absolute;
    top: 50%;
    left: 50%;

    max-width: 25px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.details {
  flex-grow: 1;

  padding: .313rem .25rem 0 0;
}

.extraDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  padding: 10px 0px;

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 .75rem;
    width: 6rem;
    border-left: 1px solid $neutrals6;

    @include dark {
      border-color: $light-twenty-five;
    }

    @include s {
      width: 4.25rem;
      padding: 0 .25rem;
    }
  }
  .amountColumn {
    width: 5rem;
    @include s {
      width: 4rem;
      padding: 0 .25rem;
    }
  }
  .dateColumn {
    width: 6.625rem;
    @include s {
      width: 5rem;
    }
  }
}

.position {
  @include SairaRegular;
  font-size: .875rem;
  color: $neutrals4;
  font-weight: 500;
  @include dark {
    color: $light-fifty;
  }
  @include s {
    font-size: .75rem;
  }
}

.name {
  @include SairaRegular;
  font-size: .875rem;
  color: $neutrals2;
  font-weight: 500;
  line-height: .75rem;
  @include dark {
    color: $light;
  }
  @include s {
    font-size: .75rem;
  }
}
.ellipsisLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 4.5rem;
  max-width: 6rem;
  @include d {
    min-width: 3.75rem;
    max-width: 5rem;
  }
  @include m {
    min-width: 4.5rem;
    max-width: 6rem;
  }
  @include a {
    max-width: 4.5rem;
  }
  @include s {
    min-width: 2.75rem;
    max-width: 3rem;
  }
}

.note {
  margin: 10px 0;
  padding: 9px 12px;

  display: inline-block;

  font-size: 14px;
  font-weight: 500;

  font-style: normal;
  line-height: 24px;

  color: #636363;

  background: #eaeaff;
  border-radius: 12px;
}

.verify {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 25px;
  height: 25px;
}

.itemDetails {
  flex-grow: 1;

  padding: 5px;
}

.properties {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem
}

.property {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  padding: .5rem 1rem;
  margin: .5rem 0;
  gap: .25rem;

  outline: .063rem solid $neutrals6;
  border-radius: .75rem;

  width: fit-content;
  max-width: 10rem;
  overflow-wrap: break-word;
  max-width: calc(33.33% - 1rem);
  flex: 0 0 calc(33.33% - 1rem);

  @include d {
    max-width: calc(50% - 1rem);
    flex: 0 0 calc(50% - 1rem);
  }

  @include t {
    max-width: calc(25% - 1rem);
    flex: 0 0 calc(25% - 1rem);
  }

  @include m {
    max-width: calc(33.33% - 1rem);
    flex: 0 0 calc(33.33% - 1rem);
  }

  @include s {
    max-width: calc(50% - 1rem);
    flex: 0 0 calc(50% - 1rem);
  }

  .key {
    font-weight: 700;
    line-height: 1.075rem;
    overflow-wrap: break-word;
    word-break: break-all;
    color: $orange-kephi;
    padding-bottom: .25rem;
    @include dark {
      color: $light;
    }
  }
  .value {
    font-weight: 700;
    line-height: 1.075rem;
    color: $neutrals4;
  }

  @include dark {
    background: transparent;
  }
}