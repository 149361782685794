@import "../../../styles/helpers.scss";

.container {
  margin-right: 0.094rem;

  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: inherit;
  }
  &::-webkit-scrollbar:vertical {
    width: 0.313rem;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0.313rem;
  }
  &::-webkit-scrollbar-thumb {
    padding-left: 10px;
    background-color: $neutrals4 !important;
    background: $neutrals4 !important;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $neutrals6 !important;
    background: $neutrals6 !important;
    @include dark {
      background-color: $dark !important;
      background: $dark !important;
    }
  }


  @include m {
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar:vertical {
      width: 0.25rem;
    }
    &::-webkit-scrollbar:horizontal {
      height: 0.25rem;
    }
  }
}

.categoryRow {
  padding: 0 15px;
  text-align: start;
  font-weight: 600;

  color: $neutrals4;

  @include dark {
    color: $light;
  }
}

.resultRow {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 40px;

  border-radius: 25px;

  transition: 0.5s;
}

.notFoundMessage {
  padding: 5px 10px;

  width: 100%;
  height: 40px;

  text-align: center;

  color: $neutrals5;
}
