@import "../../styles/helpers";

.container {
  max-width: 25rem;

  @include a {
    padding: 0 2rem !important;
  }
  @include s {
    padding: 0 1.25rem !important;
  }
}