@import "../../styles/helpers";

.loaderContainer{
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 700px;
}
.loader {
  margin: auto;
  flex-grow: 1;
  width: 2rem;
  height: 2rem;

  svg {
    width: 100%;
    height: 100%;
    fill: $neutrals4;
  }
}