@import "../../styles/helpers.scss";
@import "../../styles/blocks/button.scss";

.section {
  padding-top: 0 !important;
}

.container {
  @include m {
    padding: 0 1.5rem !important;
  }
}

.rowSection {
  transition: .2s;
  z-index: 200;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;


  &.dark{
    @include dark {
      background: $dark-medium;
    }
  }
}
.tabs {
  display: flex;
  flex-direction: row;
  transition: .25s;
  z-index: 200;
  height: 100%;
  gap: 2rem;
  padding-top: 1.25rem;
  padding-bottom: .5rem;
  align-items: center;
  justify-content: center;

  .galleryButton {
    display: flex;
  }

  &.dark {
    @include dark {
      transition: .25s;
      opacity: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      top: 0;
      .galleryButton {
        transition: .25s;
        display: none;
      }
    }
  }

  &.showfloating {
    transition: .25s;
    opacity: 1 !important;
    animation: show 1s ease;
    padding-top: 1.25rem !important;
    padding-bottom: .5rem !important;
    height: 100% !important;
    .galleryButton {
      transition: .25s;
      display: flex !important;
    }
    @include m {
      gap: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      justify-content: flex-start;
      // padding: 1rem 3.75rem;
    }

    // @include m {
    //   padding: .75rem 3.125rem !important;
    //   // align-items: center;
    // }
  }
  @include t {
    gap: 1.25rem;
    padding-left: 60px;
    padding-right:60px;
  }
  @include m {
    gap: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    justify-content: flex-start;
  }
}


.filterContainer {
  padding-top: .75rem !important;
  position: sticky;
  transition: .25s;
  z-index: 200;

  &.dark{
    top: 0;
  }

  @include m {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
    padding-right: 0rem !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  justify-content: space-between;
  border-bottom: .1px solid $light-fifty;

  &.borderBottomOff {
    border-bottom: none;
  }

  @include x {
    gap: 3rem;
  }
  @include d {
    gap: 2rem;
  }
  @include t {
    gap: 1.5rem;
  }
  @include m {
    border-bottom: none;
  }
}

.filtersContainer {
  margin-left: auto;

  @include t {
    margin: 0;
    // display: inline-flex;
    // justify-content: flex-start;
    // overflow-x: auto;

    // &::-webkit-scrollbar {
    //   display: none;
    // }

    // &::-webkit-scrollbar-thumb {
    //   margin-top: 10px;
    //   padding-top: 10px;
    //   background-color: $neutrals5;
    //   border-radius: 10px;
    // }
  }

  @include t {
    display: inline-flex;
    justify-content: flex-start;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.openGallery {
  @include Handjet;
  font-style: normal;
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 96%;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  color: $light-fifty;
  transition: .1s;

  @include x {
    font-size: 2.9rem;
    line-height: 90%;
  }
  @include d {
    font-size: 2.75rem;
    line-height: 85%;
  }
  @include t {
    font-size: 2.5rem;
    line-height: 70%;
  }
}

.kephiLogo {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
  transition: .1s;
  img {
    margin-left: .5rem;
    width: 11rem;
  }
  @include x {
    font-size: 1rem;
    img {
      width: 10.5rem;
    }
  }
  @include d {
    font-size: .9rem;
    img {
      width: 10rem;
    }
  }
  @include t {
    font-size: .75rem;
    img {
      width: 9rem;
    }
  }
}

.zurbaranLogo {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.25rem;
  transition: .1s;
  img {
    margin-left: .5rem;
    width: 8rem;
  }
  @include x {
    font-size: 1rem;
    img {
      width: 7.75rem;
    }
  }
  @include d {
    font-size: .9rem;
    img {
      width: 7rem;
    }
  }
  @include t {
    font-size: .75rem;
    img {
      width: 6.5rem;
    }
  }
}

.openGallery, .kephiLogo, .zurbaranLogo {
  @include t {
    display: none;
  }
}

.outlinedButton {
  font-size: 1rem;
  font-weight: 400;
  transition: .25s;
  text-align: center;
  padding: .5rem 1.5rem;
  border-radius: 5.625rem;
  display: inline-flex;
  align-items: center;
  gap: .35rem;
  white-space: nowrap;

  background-color: transparent;
  border: 1px solid $light;
  color: $light;

  img {
    transition: .15s;
    filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
  }

  &:hover {
    font-weight: 700;
    img {
      transition: .15s;
      filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
    }
  }

  &.active {
    font-weight: 700;
  }

  @include t {
    padding: .5rem 1rem;
    // padding: .75rem;
    // .filterLabel {
    //   display: none;
    // }
    img {
      display: inline-flex;
      // transition: .15s;
      // filter: brightness(0) saturate(100%) invert(94%) sepia(2%) saturate(767%) hue-rotate(30deg) brightness(107%) contrast(98%);
    }
  }
}

.galleryButton {
  box-sizing: border-box;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);
  padding: .5rem 2rem;

  &:hover, &.active {
    border: 1px solid transparent;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 5.625rem;
      padding: 1px;
      margin: -1px;
      background: $gradient-gallery;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }
  }
}

.filterButton {
  @include m {
    display: none;
  }
}

.list {
  z-index: 0;
  margin: 0 -16px;
  display: flex;
  flex-wrap: wrap;
  @include m {
    justify-content: center;
  }
}

.card {
  margin: 32px 16px 0;

  max-width: calc(25% - 32px);
  flex: 0 0 calc(25% - 32px);
  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);

  @include d {
    max-width: calc(33.333% - 32px);
    flex: 0 0 calc(33.333% - 32px);
  }

  @include m {
    max-width: calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
  }

  @include a {
    margin: 20px 8px 0;

    max-width: calc(100% - 32px);
    flex: 0 0 calc(100% - 32px);
  }
}

.outerContainer {
  min-width: 42rem;
}
.modalContainer {
  padding: 1rem 1.75rem;
}


.mobileFilterContainer {
  display: none;
  @include m {
    display: flex;
    flex-direction: column;
    transition: .25s;
  }
}
.filterHeader {
  display: flex;
  padding: 1rem 0 .5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-fifty;
  width: 100%;

  font-size: 1rem;
  font-weight: 400;
  line-height: .75rem;
  color: $light-fifty;

  cursor: pointer;
  svg {
    fill: $light-fifty;

    transition: transform .5s;
  }

  &.active {
    color: $light;
    svg {
      transform: rotate(180deg);
      fill: $light;
    }
  }
}

.mobileFilter {
  transition: .25s;
  height: 0;
  visibility: collapse;
  z-index: 1;

  &.visible {
    visibility: visible;
    transform-origin: top center;
    animation: slideDown .25s ease-in-out forwards;
  }

  &:not(.visible){
    animation: slideUp .5s ease-in forwards;
    transform-origin: top center;
    height: auto;
  }
}

@keyframes slideDown {
  0% {
    height: 0;
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    height: auto;
    opacity: .5;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-80px);
    height: 0;
  }
}
.noNfts {
  width: 100%;
}