@import "../../styles/helpers";

$default-margin: 20px;

.footer {
  padding-top: 20px;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  max-width: 100%;

  font-size: 15px;

  user-select: none;

  background: $white;
  border-top: 1px solid $neutrals6;
  @include dark {
    background: $dark;
    border-color: $neutrals3;
  }

  .container {
    padding: 1.25rem 5rem;
    @include t {
      padding: .625rem 2rem;
    }
    @include m {
      padding: .625rem 1rem;
    }
  }
}

.wrapper {
  position: relative;

  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;

  @include a {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.col {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  @include a {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &.colCenter {
      justify-content: center;
    }
  }
}

.logoContainer{
  display: flex;
  flex-direction: column;
}
.logo {
  img {
    height: 2.325rem;
    transition: .75s;
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  @include a {
    text-align: center;
    img {
      height: 2.5rem;
    }
  }
}
.themeContainer{
  display: none;
  // display: inline-flex;
  // align-items: center;
  // padding-top: 1rem;
  // font-size: .75rem;
  // @include a {
  //   padding-top: .5rem;
  // }
}

.themeText {
  padding-right: .5rem;
  @include dark {
    color: $neutrals5;
  }
}

.slogan {
  margin-top: 10px;

  width: fit-content;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;

  color: $neutrals2;

  @include a {
    display: none;
  }
  @include dark {
    color: $light-fifty;
  }
}

.linkGroups {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;


  .col:not(:last-child) {
    margin-right: .75rem;

    @include m {
      margin-right: $default-margin;
    }
  }

  @include a {
    margin-top: .5rem;
    justify-content: center;
    align-items: center;
  }
}

.head {
  @include body-16-bold;
  color: $neutrals2;
}

.textLinks {
  position: relative;

  display: flex;
  flex-direction: column;

  height: fit-content;

  font-size: 14px;
  font-weight: bold;

  font-style: normal;
  line-height: 16px;

  color: $neutrals4;

  a {
    color: inherit;

    &:hover {
      color: darken($neutrals4, 10);
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.dropdownWrapper {
  @include a {
    display: none;
  }
}
.languageDropdownHead{
  display: flex;
  align-items: center;
  padding: 0 .5rem 0 .625rem;
  height: 2.5rem;
  font-size: 14px;
  border-radius: 20px;
}
.languageDropdownArrow {
  right: 2px;
}
.socials {
  display: flex;
  justify-content: flex-start;

  @include a {
    position: unset;

    margin-top: .5rem;
  }
}

.social {
  svg {
    fill: $neutrals3;
    @include dark {
      fill: $light;
    }

    transition: fill $animation-timing-default;
  }

  &:hover {
    svg {
      fill: $orange-kephi;
    }
  }

  &:not(:last-child) {
    margin-right: .75rem;

    @include a {
      margin-right: calc(1rem / 2);
    }
  }
}

.divider {
  content: "";

  margin-top: 15px;

  width: 100%;
  height: 1px;

  background: $neutrals4;
  opacity: 0.2;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @include r("1024") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.rowFooter {
  padding: 15px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  font-size: 12px;
  font-weight: normal;

  font-style: normal;
  line-height: 20px;

  @include a {
    flex-direction: column;
  }
}

.copyright {
  color: $neutrals4;
  font-feature-settings: "liga" off;

  @include dark {
    color: $light-fifty;
  }
}

.poweredBy{
  display: flex;
  align-items: center;
  color: $neutrals4;
  font-size: .6rem;
  text-transform: uppercase;
  @include dark {
    color: $light-fifty;
  }
  img {
    margin-left: .5rem;
    width: 7.5rem;
  }

  @include a {
    margin-top: .25rem;
  }
}
