@import "../../styles/helpers";

.label {
  margin-bottom: 12px;

  @include hairline-2;
  color: $neutrals5;
}

.textarea {
  resize: none;

  padding: 10px 14px;

  width: 100%;
  height: 96px;

  color: $neutrals2;
  background: none;
  border: 2px solid $neutrals6;
  border-radius: 12px;

  transition: border-color 0.2s;

  @include poppins;
  @include caption-bold-1;

  @include placeholder {
    color: $neutrals4;
  }

  &:focus {
    border-color: $neutrals4;
  }

  @include dark {
    border-color: $neutrals4;
    color: $neutrals6;
    &:focus {
      border-color: $neutrals5;
    }
  }
}
