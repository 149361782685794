@import "../../styles/helpers";

.gallery {
  margin: (-8px) -4px 16px;

  display: flex;
  flex-wrap: wrap;

  &:hover{
    .preview {
      & > img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}

.gallerySkeleton {
  margin: (-8px) -4px 16px;
}

.collectionHome {
  border-radius: 8px;
}

.collectionItemCounter {
  flex-shrink: 0;
  box-shadow: inset 0 0 0 1px $light;
  color: $dark-medium;
  display: inline-block;
  padding: .5rem;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  @include SairaBold;

  @include dark {
    color: $light-fifty;
  }
}

.preview {
  margin: 8px 4px 0;

  width: calc(33.333% - 8px);
  flex: 0 0 calc(33.333% - 8px);
  overflow: hidden;
  border-radius: 8px !important;

  &:first-child {
    flex: 0 0 calc(100% - 8px);

    img {
      height: 264px;
    }
  }

  &:not(:first-child) {
    img {
      height: 84px;
    }
  }

  img {
    width: 100%;

    border-radius: 8px;
    object-fit: cover;
    transition: transform 1s;
    overflow: hidden;
  }
}

.previewSkeleton {
  height: 264px !important;
  width: calc(100% - .5rem) !important;
  flex: 0 0 calc(100% - 1rem) !important;
}
.tokenSkeletonWrapper{
  display: flex;
}
.tokenSkeletonCard {
  margin: 8px 4px 0;
  border-radius: 8px !important;
  width: calc(33.333% - .5rem) !important;
  flex: 0 0 calc(33.333% - .5rem) !important;
}
.tokenSkeleton {
  height: 84px !important;
  width: 100% !important;
}

.subtitle {
  margin-bottom: 8px;

  @include body-bold-1;
  color: $neutrals2;
  @include SairaBold;
  font-size: 1.5rem;
  line-height: 2rem;

  @include dark {
    color: $light-fifty;
  }
}

.line {
  display: flex;
  align-items: center;
}

.user {
  margin-right: auto;

  display: flex;
  align-items: center;

  color: $neutrals3;

  @include dark {
    color: $neutrals6;
  }

  span {
    font-weight: 500;
  }
}

.avatar {
  margin-right: 12px;

  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}
