@import "../../styles/helpers";

.title {
  margin-bottom: 32px;
  padding-right: 56px;
}

.text {
  margin-bottom: 32px;

  @include body-2;
  color: $neutrals4;
}

.info {
  margin-bottom: 3px;

  @include body-bold-1;
}

.input {
  width: 100%;
  height: 48px;

  color: $neutrals2;
  border-bottom: 1px solid $neutrals6;

  @include poppins;
  @include body-bold-2;

  @include placeholder {
    color: $neutrals4;
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
