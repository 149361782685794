@import "../../styles/helpers";
$default-margin: .75rem;
$default-mobile-margin: .325rem;
.header {
  position: sticky;
  z-index: 500;
  top: 0;
  // border-bottom: .1px solid $neutrals4;
  // backdrop-filter: blur(.5rem);
  @include dark {
    background: $dark-medium;
  }
  width: 100%;
  transition: 1s;
  @include m {
    padding-right: 0;
    padding-left: 0;
  }
  .container {
    padding: 0 5rem;
    @include t {
      padding: 1rem 2rem;
    }
    @include m {
      padding: 1rem 1rem;
    }
    @include a {
      padding: 1rem .625rem;
    }
  }
  // &:not(.active) {
  //   .languageDropdownHead,
  //   .head,
  //   .user {
  //     box-shadow: inset 0 0 0 1px $neutrals4;
  //     &:hover {
  //       box-shadow: inset 0 0 0 1px $orange-kephi;
  //     }
  //   }
  //   .user {
  //     @include m {
  //       border-radius: 0;
  //       box-shadow: none;
  //     }
  //   }
  //   .searchBar {
  //     border-color: $neutrals4;
  //     &:hover {
  //       border-color: $orange-kephi;
  //     }
  //     &.hidden {
  //       display: none;
  //     }
  //   }
  //   .logo {
  //     transition: 1s;
  //     img {
  //       content: url('../../assets/img/icons/kephi-logo-light.svg') !important;
  //       @include a {
  //         content: url('../../assets/img/icons/logo.svg') !important;
  //       }
  //     }
  //   }
  // }
  &.active {
    background: $white;
    @include dark {
      background: $dark-medium;
    }
  }
  &.relative {
    position: relative;
  }
}
.container {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 12px;
  }
  & > .button {
    z-index: 0;
    @include a {
      // display: none;
    }
  }
  & > .search {
    @include a {
      display: none;
    }
  }
}
.logo {
  position: relative;
  z-index: 12;
  margin-right: 28px;
  display: inline-block;
  width: 100%;
  max-width: 180px;
  flex-shrink: 0;
  @include d {
    margin-right: auto;
  }
  @include t {
    width: auto;
  }
  img {
    height: 3.125rem;
    // width: 12.844rem;
    transition: .75s;
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    &:last-child {
      display: none;
      @include t {
        display: unset;

      }
    }
    &:first-child {
      @include t {
        display: none;
      }
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  // flex-grow: 1;
  padding-left: 3rem;
  @include d {
    padding-left: 2rem;
  }

  @include t {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 140px 24px 40px;
    visibility: hidden;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    min-height: 100vh;
    // background: $dark-bg;
    background: $white;
    opacity: 0;
    box-shadow: 0 64px 64px rgba(31, 47, 70, 0.2);
    transition: all 0.2s;
    @include dark {
      background: $dark;
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  .button {
    display: none;
    @include a {
      margin-top: 16px;
      display: inline-flex;
    }
  }
  .search {
    display: none;
    @include a {
      display: inline-flex;
    }
  }
}
.nav {
  margin-right: auto;
  display: flex;
  align-items: center;
  // border-left: 2px solid $neutrals3;
  @include d {
    // display: none;
  }
  @include t {
    margin: 0 0 40px;
    display: flex;
    flex-direction: column;
    border: none;
  }
  @include dark {
    border-color: $neutrals3;
    color: $light;
  }
}
.link {
  margin: 0 .75rem;
  transition: color $animation-timing-default;
  
  padding: 2.062rem 0 1.626rem;
  overflow: hidden;
  @include SairaRegular;
  @include body-14;
  font-size: 1rem;

  @include d {
    margin: 0 .5rem;
  } 
  @include t {
    margin-left: 0;
    font-size: 24px;
    line-height: 64px;
  }
  color: $light-fifty;
  &:hover{
    cursor: pointer;
    color: $orange-kephi;
    @include dark {
      color: $light-fifty;
    }
  }
  &.active {
    box-shadow: inset 0 -2px 0 $orange-kephi;
    @include dark {
      box-shadow: inset 0 -2px 0 $orange-kephi;
    }
  }
  &.disabled {
    color: $lightgray;
  }
  // &:nth-child(n+3) {
  //   display: none;
  //   @include t {
  //     display: block;
  //   }
  // }
  @include dark {
    border-color: $neutrals3;
    color: $neutrals4;
  }
  @include dark {
    color: $light;
  }
}
.dropdownWrapper {
  margin-right: $default-margin;
  @include m {
    margin-right: $default-mobile-margin;
  }
}
.languageDropdownHead{
  padding: 0 .5rem;
  height: 2.25rem;
  height: 2.5rem;
  font-size: 14px;
  border-radius: 20px;
  @include m {
    padding: 0 .5rem;
  }
}
.languageDropdownHead{
  display: flex;
  align-items: center;
  padding: 0 .5rem 0 .625rem;
  height: 2.5rem;
  font-size: 14px;
  border-radius: 20px;
}
.languageDropdownArrow {
  right: 2px;
}
.input {
  padding: 0 42px 0 16px;
  visibility: hidden;
  width: 100%;
  height: 2.25rem;
  height: 2.5rem;
  background: none;
  border: 2px solid $neutrals6;
  border-radius: 8px;
  transition: border-color $animation-timing-default;
  &:focus {
    border-color: $orange-kephi;
  }
  @include poppins;
  @include caption-2;
  @include placeholder {
    color: $orange-kephi;
  }
  @include dark {
    color: $light;
    border-color: $neutrals3;
  }
  @include a {
    visibility: unset;
  }
}
.result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 42px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px $neutrals6;
  transition: box-shadow $animation-timing-default;
  svg {
    transition: fill $animation-timing-default;
    fill: $orange-kephi;
  }
  &:hover {
    box-shadow: inset 0 0 0 2px $orange-kephi;
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
  @include a {
    border-radius: unset;
    box-shadow: unset;
  }
}
.notification {
  margin-right: $default-margin;
  @include m {
    margin-right: $default-mobile-margin;
  }
}
.user {
  // height: 2.25rem;
  margin-right: $default-margin;
  @include m {
    margin-right: $default-mobile-margin;
  }
}
.burger {
  display: none;
  @include t {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &.active {
      &::before {
        transform: translateY(0) rotate(-45deg);
      }
      &::after {
        transform: translateY(0) rotate(45deg);
      }
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 16px;
      left: 6px;
      width: 20px;
      height: 2px;
      background: $neutrals4;
      border-radius: 2px;
      transition: transform 0.2s;
    }
    &::before {
      transform: translateY(-4px);
    }
    &::after {
      transform: translateY(3px);
    }
  }
}
.head {
  position: relative;
  margin-right: $default-margin;
  width: 2.25rem;
  height: 2.25rem;
  width: 2.5rem;
  height: 2.5rem;

  padding: 0 18px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px $neutrals6;
  transition: box-shadow $animation-timing-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include dark {
    box-shadow: inset 0 0 0 1px $neutrals3;
  }
  svg {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    transition: fill $animation-timing-default;
    fill: $orange-kephi;
    @include dark {
      fill: $neutrals4;
    }
  }
  &:hover {
    box-shadow: inset 0 0 0 1px $orange-kephi;
    svg {
      fill: darken($orange-kephi, 10);
    }
    @include dark {
      box-shadow: inset 0 0 0 1px $neutrals5;
      svg {
        fill: $neutrals5;
      }
    }
  }
  &.active {
    &::before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 8px;
      right: 8px;
      width: 12px;
      height: 12px;
      background: $orange-kephi;
      border-radius: 50%;
      @include dark {
        background: lighten($orange-kephi, 5);
      }
    }
  }
  @include m {
    margin-right: $default-mobile-margin;
  }
}
.button {
  &:hover {
    color: white;
  }
}
.loginButton {
  padding: 0 1.5rem !important;
  background: $gradient-gallery !important;

  img {
    display: block;
    margin-right: .5rem;
    margin-left: -.25rem;
  }

  &:hover {
    color: white;
    .loginText {
      font-size: .879rem;
      font-weight: bolder !important;
    }
  }
  @include m {
    padding: 0 !important;
    width: 40px;
    img {
      margin: 0;
    }
  }
}
.loginText {
  font-size: .875rem;
  @include m {
    display: none;
  }
}

.themContainer {
  display: none;
}