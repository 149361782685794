@import "../../../styles/helpers";

.list {
}

.noItemsFound {
  margin-top: 69px;

  text-align: center;
}
.noItemsText {
  color: $neutrals4;
}

.loader {
  margin: 32px auto 0;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: $neutrals4;
  }
}
