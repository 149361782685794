@import "../../styles/helpers";

.checkbox {
  position: relative;

  display: inline-block;

  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.input {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;

  &:checked + .inner .tick {
    background: $orange-kephi;
    border-color: transparent;

    &::before {
      opacity: 1;
    }
  }
}

.inner {
  display: flex;

  &:hover {
    .tick {
      border-color: $gradient;
    }
  }
}

.tick {
  position: relative;

  margin-right: 12px;

  width: 24px;
  height: 24px;

  border: 2px solid $neutrals6;
  @include dark {
    border-color: $neutrals4;
  }
  border-radius: 4px;

  transition: all 0.2s;
  flex-shrink: 0;

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    width: 14px;
    height: 10px;

    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 100% auto;
    opacity: 0;

    transition: opacity 0.2s;
    transform: translate(-50%, -50%);
  }
}

.text {
  @include body-14;
  // color: $neutrals3;
}
