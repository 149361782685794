@mixin w {
  @media only screen and (max-width: 1419px) {
    @content;
  }
}

@mixin x {
  @media only screen and (max-width: 1339px) {
    @content;
  }
}

@mixin d {
  @media only screen and (max-width: 1179px) {
    @content;
  }
}

@mixin t {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin a {
  @media only screen and (max-width: 639px) {
    @content;
  }
}

@mixin s {
  @media only screen and (max-width: 474px) {
    @content;
  }
}
