@import '../../../../styles/helpers.scss';

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @include d {
    gap: 1.35rem;
  }
  @include t {
    gap: 1.25rem;
    display: inline-flex;
    justify-content: flex-start;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.filterButton {
  img, svg {
    filter: drop-shadow(0px 0px 0px $light-twenty-five);
    -webkit-filter: drop-shadow(0px 0px 0px $light-twenty-five);
    -moz-filter: drop-shadow(0px 0px 0px $light-twenty-five);
    opacity: .5;
  }
  &.active, &:hover {
    img {
      filter: drop-shadow(0px 0px 0px $light) !important;
      -webkit-filter: drop-shadow(0px 0px 0px $light) !important;
      -moz-filter: drop-shadow(0px 0px 0px $light) !important;
      opacity: 1;
    }
  }
  &.active {
    box-shadow: inset 0 -2.5px 0 $orange-kephi;
  }
  &.error {
    img {
      // filter: invert(43%) sepia(86%) saturate(5124%) hue-rotate(321deg) brightness(84%) contrast(89%);
      filter: drop-shadow(0px 0px 0px red) contrast(100%) brightness(100%) saturate(500%);
      // -webkit-filter: drop-shadow(0px 0px 0px red) contrast(100%) brightness(100%) saturate(500%);
      // -moz-filter: drop-shadow(0px 0px 0px red) contrast(100%) brightness(100%) saturate(500%);
      opacity: 1;
    }
  }
  margin-bottom: 2px;

  @include m {
    &:last-child {
      margin-right: 1.5rem;
    }
  }
}