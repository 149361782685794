@import "../../../styles/helpers.scss";

.rowContainer {
  padding: 7px 10px 5px 10px;

  display: flex;
  flex-direction: row;
  text-align: start;

  height: 65px;
  width: 100%;
  overflow: visible;

  transition: 0.5s;
}

.image {
  width: 50px;
  height: 50px;
}

.description {
  padding: 0 10px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.name {
  padding: 0 2px;
  border-radius: 5rem !important;
}

.address {
  padding: 0 2px;
  border-radius: 5rem !important;
}
