@import "../../../../../../../styles/helpers";

.title {
  @include SairaBold;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.375rem;

  @include dark {
    color: $light;
  }
}

.upload {
  display: flex;
  margin-top: 1.5rem;
  gap: 1.5rem;

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 10rem;
  }
  .empty {
    min-width: 8rem;
    height: 8rem;
    border-radius: 100%;
    background: $light-purple;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  .text {
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.25rem;
    color: $neutrals4;
    @include dark {
      color: $light-fifty;
    }
  }
}

.file {
  position: relative;

  display: inline-block;
  overflow: hidden;
}

.load {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 140px;

  opacity: 0;
}

.fieldsWrapper {
  margin-top: 2rem;

  // [role=alert] {
  //   color: darken($yellow, 20);
  //   padding: .25rem .25rem .75rem;
  //   @include dark {
  //     color: $yellow;
  //   }
  // }

  // .field {
  //   input::placeholder {
  //     color: $orange-kephi;
  //   }

  //   &:not(:last-child) {
  //     margin-bottom: 32px;

  //     @include m {
  //       margin-bottom: 20px;
  //     }
  //   }
  // }
}

.urlMessage {
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.25rem;
  color: $light-fifty;
  margin-top: -1rem;

  &.onError {
    margin-top: 0;
  }
}

.uploadButton {

}

.button {
  margin: 1.5rem 0 1rem;
  @include SairaBold;
  font-size: .875rem;
  font-weight: 600;
  line-height: 22px;
  height: 2.5rem !important;

  width: 100%;
  background: $gradient-gallery !important;

  &:hover {
    opacity: .9;
  }
}

.errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 auto;
    padding: .25rem .75rem;
    border-radius: 1rem;
    color: $neutrals3;
    background-color: $red-light;

    @include dark {
      background-color: $red-gray;
      color: $light;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.modalSuccessOuter {
  max-width: 21.125rem;
}
