@import "../../../../styles/helpers";

@keyframes maximize {
  0% {
    font-size: 1rem;
    width: 3.125rem;
    height: 3.125rem;
  }
  100% {
    font-size: 1.5rem;
    width: 3.75rem;
    height: 3.75rem;
  }
}
@keyframes minimize {
  0% {
    font-size: 1.5rem;
    width: 3.75rem;
    height: 3.75rem;
  }
  100% {
    font-size: 1rem;
    width: 3.125rem;
    height: 3.125rem;
  }
}

@keyframes swipeRight {
  0% {
    transform: translateX(-75px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes swipeLeft {
  0% {
    transform: translateX(75px);
  }
  100% {
    transform: translateX(0);
  }
}

.stepperContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 25rem;
  margin: 0 auto;
  transition: .35s;
}
.stepsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.75rem;
  transition: .35s;
}
.stepContainer {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.stepNumber {
  transition: .35s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.563;
  letter-spacing: 0em;
  text-align: center;

  width: 3.125rem;
  height: 3.125rem;
  border: 2px solid $light-twenty-five;
  border-radius: 100%;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);

  &.active {
    animation: maximize .35s ease-in forwards;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.375rem;
    letter-spacing: 0em;
    text-align: center;

    width: 3.75rem;
    height: 3.75rem;

    border-color: transparent;
    background-color: transparent;
    -webkit-mask:
    linear-gradient($dark 0 0) content-box,
    linear-gradient($dark 0 0);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 5.625rem;
      padding: 2px;
      margin: -2px;
      background: $gradient-gallery;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
    }
  }
  &.done {
    background: $dark-light;
    border-color: $dark-light;
    animation: minimize .35s ease-in forwards;
  }
}


.separatorLine {
  display: none;
  width: 1.875rem;
  height: 0;
  border: 1px solid $light;
  z-index: 1;


  &.show {
    display: flex;
    transition: .5s;
  }
  &.showRight {
    display: flex;
    animation: swipeRight .4s ease-in forwards;
  }
  &.showLeft {
    display: flex;
    animation: swipeLeft .4s ease-in forwards;
  }

  @include s {
    width: 1.75rem;
  }
}

.errorMessage {
  div {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 0 auto;
    padding: .25rem .75rem;
    border-radius: 1rem;
    color: $neutrals3;
    background-color: $red-light;

    @include dark {
      background-color: $red-gray;
      color: $light;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.modalSuccessOuter {
  max-width: 21.125rem;
}

.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: $dark-light;
  opacity: 0.3;
  z-index: 1000;
  height: 100%;
  width: 100%;
}

.wertContainer {
  padding: 4rem 1.75rem 1rem;
}
.wertOuter {
  max-width: fit-content;
}