@import "../../../styles/helpers";

.user {
  position: relative;

  border-radius: 20px;
  box-shadow: inset 0 0 0 1px $neutrals6;

  transition: box-shadow 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include dark {
    box-shadow: inset 0 0 0 1px $neutrals3;
    color: $neutrals4;
  }
  &:hover {
    box-shadow: inset 0 0 0 1px $orange-kephi;

    @include dark {
      box-shadow: inset 0 0 0 1px $neutrals4;
    }
    @include m {
      box-shadow: none;
    }
  }
  @include m {
    position: static;
    box-shadow: none;
  }
}

.head {
  position: relative;

  padding: .25rem;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;

    border-radius: 50%;
  }
}

.wallet {
  display: flex;
  margin-left: 8px;

  color: $neutrals3;

  @include m {
    display: none;
  }

  @include dark {
    color: $neutrals4;
  }
}

.currency {
  margin-left: 3px;
  color: $orange-kephi;
  @include dark {
    color: lighten($orange-kephi, 5);
  }
}

.body {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 997;
  top: calc(100% + 20px);
  left: 50%;

  margin-left: -128px;
  padding: 32px 16px 20px;

  width: 256px;

  background: $light;
  border-radius: 12px;
  opacity: 0;
  box-shadow: 0 16px 64px 0 $neutrals5;

  height: auto;
  // max-height: 74vh;

  // @include m {
  //   max-height: 80vh;
  // }
  // @include a {
  //   max-height: auto;
  // }

  transform: scale(0.9);
  transform-origin: 50% 50px;
  animation: showUser 0.4s forwards;
  will-change: transform;

  // overflow-y: auto;
  // overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   -webkit-appearance: inherit;
  // }
  // &::-webkit-scrollbar:vertical {
  //   width: 0.313rem;
  // }
  // &::-webkit-scrollbar:horizontal {
  //   height: 0.313rem;
  // }
  // &::-webkit-scrollbar-thumb {
  //   padding-left: 10px;
  //   background-color: $neutrals5 !important;
  //   border-radius: 10px;
  //   @include dark {
  //     background-color: $neutrals4 !important;
  //   }
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 10px;
  //   background-color: white !important;
  //   @include dark {
  //     background-color: $dark !important;
  //   }
  // }

  @include t {
    right: 0;
    left: auto;
  }

  @include m {
    top: calc(100% + 16px);
    right: 16px;
    left: 16px;

    margin: 0;

    width: auto;
  }

  &::before {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 100%;

    width: 32px;
    height: 13px;

    background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A")
      no-repeat 100% 50% / 100% auto;

    transform: translateX(-50%);

    @include m {
      display: none;
    }
  }

  @include dark {
    background: $neutrals2;
    color: $neutrals5;
    box-shadow: none;
    border: 1px solid $neutrals3;

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
    }
  }
}

@keyframes showUser {
  0% {
    opacity: 0;

    transform: scale(0.9);
  }

  100% {
    opacity: 1;

    transform: scale(1);
  }
}

.name {
  @include body-bold-1;
  @include dark {
    color: $neutrals5;
  }
}

.code {
  margin-bottom: 8px;

  display: flex;
  align-items: center;
}

.number {
  font-weight: 500;

  color: $neutrals4;
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;
    @include dark {
      fill: lighten($orange-kephi, 5);
    }

    transition: fill $animation-timing-default;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.wrap {
  margin-bottom: 7px;
  padding: 8px;

  border: 1px solid $neutrals6;
  border-radius: 16px;
  @include dark {
    border-color: $neutrals3;
  }
}

.line {
  margin-bottom: 10px;

  display: flex;
  align-items: center;
}

.preview {
  margin-right: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  // background-color: $orange-kephi;
  border-radius: 50%;
  flex-shrink: 0;

  img {
    width: 42px;
  }
}

.info {
  @include caption-2;
  color: $neutrals4;
}

.price {
  @include body-bold-1;
  display: flex;
  align-items: center;

  font-size: 18px;

  span {
    margin-right: 5px;

    display: block;
    overflow: hidden;

    max-width: 90px;

    text-overflow: ellipsis;
  }
}

.button {
  padding: 0 10px;

  width: 100%;
  height: 32px;

  font-size: 14px;
}

.item {
  padding: 17px 0 12px;

  display: flex;
  align-items: center;

  color: $orange-kephi;
  @include dark {
    color: $neutrals4;
  }

  @include button-2;

  &:not(:last-child) {
    border-bottom: 1px solid $neutrals6;

    @include dark {
      border-color: $neutrals3;
    }
  }

  // &[href] {
  &:hover {
    cursor: pointer;

    color: darken($orange-kephi, 10);
    @include dark {
      color: $neutrals5;
    }

    svg {
      fill: darken($orange-kephi, 10);
      @include dark {
        fill: $neutrals5;
      }
    }
  }

  .text:hover {
    cursor: pointer;
  }
  // }
}

.icon {
  margin-right: 8px;

  width: 20px;
  flex-shrink: 0;

  svg {
    transition: fill 0.2s;
    fill: $orange-kephi;
    @include dark {
      fill: $neutrals4;
    }
  }
}

.text {
  margin-right: auto;
}

.copy {
  margin-left: 8px;

  svg {
    fill: $orange-kephi;

    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: darken($orange-kephi, 10);
    }
  }
}

.tooltip {
  position: relative;

  display: inline-block;
  visibility: hidden;

  border-bottom: 1px solid black;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;

  padding: 5px 0;

  visibility: hidden;

  width: 120px;

  text-align: center;

  color: #fff;
  background-color: black;
  border-radius: 6px;
}

.copy:hover .tooltiptext {
  visibility: visible;
}

.swapWrapper {
  display: flex;
  justify-content: center;
  padding: .5rem 0 .25rem;
}
