@import "../../../../styles/helpers";

.line {
  margin-top: 72px;
  margin-bottom: 32px;

  display: flex;
  align-items: center;

  @include body-2;

  strong {
    font-weight: 500;
    white-space: nowrap;
  }
}

.icon {
  position: relative;

  margin-right: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  background: $green;
  border: 2px solid $green;
  border-radius: 50%;

  transition: all 0.2s;
  flex-shrink: 0;

  svg {
    transition: fill 0.2s;
    fill: $light;
  }

  .loader {
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;

    background: $light;

    // @include dark {
    background: $dark;
    // }
  }
}

.stage {
  margin-bottom: 16px;

  @include body-bold-1;
}

.item {
  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $light;

  // @include dark {
  border-color: $neutrals3;
  border-bottom: 1px solid $neutrals6;
  // }
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include a {
    overflow-x: scroll;
  }
}

.user,
.actions {
  display: flex;
  align-items: center;
}

.actions {
  flex-direction: row;
  gap: 13px;
}

.price {
  float: right;
}

.avatar {
  position: relative;

  margin-right: 16px;

  width: 48px;
  height: 48px;
  flex-shrink: 0;

  & > img {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: 50%;
    object-fit: cover;
  }
}

.name,
.amount {
  font-size: 14px;
  line-height: 24px;

  color: $neutrals4;
}

.row {
  padding-top: 12px;

  display: flex;

  border-bottom: 1px solid $neutrals3;
}

.col {
  &:first-child {
    margin-right: auto;
    padding-right: 16px;

    color: $neutrals4;
  }

  &:nth-child(2) {
    font-weight: 500;
  }
}

.btns {
  margin-top: 32px;
}

.button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
