@import "../../styles/helpers";

.wrapper {
  margin: 0 auto 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 92px;
  height: 92px;

  background: $orange-kephi;
  border-radius: 50%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 84px;

  background: $light;
  border-radius: 50%;

  @include dark {
    background: $neutrals3;
  }
}

.title {
  margin: 1.5rem 2.5rem .5rem;

  text-align: center;
}
.button {
  width: 100%;
}
.infoPanel{
  margin: 1rem 0;
}