@import "../../../styles/helpers.scss";

.rowContainer {
  padding: 7px 10px 5px 10px;

  display: flex;
  flex-direction: row;
  text-align: start;

  height: 65px;
  width: 100%;
  overflow: visible;

  cursor: pointer;

  transition: 0.5s;

  &:hover {
    color: black;
    background: aliceblue;

    @include dark {
      background: $neutrals2;
    }
  }

  @include dark {
    color: $light;
  }
}

.image {
  width: 50px;
  height: 50px;

  border-radius: 50%;
}

.description {
  padding: 0 10px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.name {
  padding: 0 2px;
}

.address {
  padding: 0 2px;

  color: grey;
}
