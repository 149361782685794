// colors
$white: #fff;
$blue: #3772ff;
$blue-light: #7997FF;
$blue-gray: #091318;
$purple: #9757d7;
$purple2: #674df6;
$purple-light: #cdb4db;
$purple-dark: #261849;
$pink: #ef466f;
$green: #45b26b;
$asphalt: #e4d7cf;
$yellow: #ffd166;
$grey: #d9dbe1;
$dark-grey: #21283c;
$red-light: #FF79A9;
$red-dark: #CC3366;
$red-gray: #160b0b;
$green-light: #e2f3ea;
$green-gray: #122b1e;

// $black: #161616;
$black: #141416;
$lightgray: #eaeaea;
$primary1: $blue;

$neutrals1: #141416;
$neutrals2: #23262f;
$neutrals3: #353945;
$neutrals4: #777e91;
$neutrals5: #b1b5c3;
$neutrals6: #e6e8ec;

$dark-bg: linear-gradient(90deg, #190f31 0%, #000 100%);
$bg: $white;
$grey-bg: #f7f7f7;
$gradient-hover: linear-gradient(90deg, #5423fd 0%, #ff3b3e 100%);
$gradient: linear-gradient(90deg, #6f45ff 0%, #ff6365 100%);
$gradient2: linear-gradient(90deg, #040404 0%, #1c0e40 100%);
$gradient3: linear-gradient(90deg, #190f31 0%, #000 100%);
$gradient4: linear-gradient(90deg, #190f31 0%, #190f31 100%);
$gradient-vertical: radial-gradient(
  883.01% 239.84% at 71.43% 0.88%,
  rgba(111, 69, 255, 0) 0%,
  #ff6365 100%
);

$gradient-blockchain: linear-gradient(90.11deg, #C830FF -2.06%, #6AC5EC 49.16%, #00FFB2 101.46%);
$gradient-gallery: linear-gradient(90deg, #C24075 0%, #FF914C 100%);
$gradient-marketing: linear-gradient(90deg, #4C93A0 0%, #9FCE7C 100%);
$gradient-gamming: linear-gradient(90deg, #ED7EFF 0%, #9156FA 60%, #7349F9 86%, #6945F9 100%);
$gradient-real-state: linear-gradient(90deg, #6BB2FF 0%, #68A8FE 8%, #5B79FB 50%, #535BF9 82%, #5051F9 100%);
$gradient-academy: linear-gradient(90deg, #FFD89C 0%, #FFA43D 100%);
$dark: #131517;
$dark-medium: #1E1F25;
$dark-light: #434351;
$light: #FDF4F4;
$light-fifty: rgba(253, 244, 244, 0.5);
$light-twenty-five: rgba(253, 244, 244, 0.25);
$light-purple: #EAEAFF;
$orange-kephi: #FF8438;