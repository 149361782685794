@import "../../styles/helpers";

.section {
  text-align: center;

  .container {
    max-width: 896px;
  }
}

.top {
  margin: 0 auto 3rem;

  max-width: 600px;

  @include m {
    margin-bottom: 2rem;
  }
}

.title {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 90%;
  @include dark {
    color: $light;
  }
}

.info {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: $light-fifty;
}

.list {
  margin-bottom: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.item {
  display: flex;
  flex-direction: column;

  width: 190px;
  height: 261px;

  border-radius: 10px;

  transition: box-shadow $animation-timing-default;
  @include heading-6;
  transition: .7s;

  @include dark {
    border: none;
  }

  &:hover {
    .preview {
      & > img {
        -webkit-transform: scale(1.01);
        transform: scale(1.1);
      }
    }
    .cardFooter {
      & > button {
        @include dark {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }

  @include m {
    height: 250px;
  }

  @include a {
    height: 210px;
  }
}

.preview {
  height: 65%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    transition: .7s;
    display: block;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    object-fit: cover;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.cardFooter {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35%;

  background: $light;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  @include dark {
    background: $dark-light;
    color: $light;
  }
}

.note {
  display: flex;
  justify-content: center;

  font-size: 1rem;
  color: $light-twenty-five;
  line-height: 1.563rem;

  margin: 0 auto;
  max-width: 446px;
}
