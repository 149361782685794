@import '../../../../styles/helpers.scss';

.title {
  font-size: .875rem;
  font-weight: bold;
  text-align: center;
}

.rowContainer {
  padding: .5rem 0 1.5rem;
  border-bottom: 1px solid $light-twenty-five;
}

.noBorder {
  padding: 1.5rem 0;
  border-bottom: none;
}

.rowFlex {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @include a {
    gap: 1rem;
  }
}

.columnRows {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.connectContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  .label {
    font-size: .875rem;
    font-weight: 700;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 0.688rem;

    @include dark {
      color: $light
    }
  }

  .description {
    font-size: .75rem;
    font-weight: 400;
    line-height: .75rem;
    letter-spacing: 0em;
    text-align: left;

    @include dark {
      color: $light-fifty;
    }
  }
}