@import "../../../../styles/helpers";

.inner {
  position: sticky;
  top: 90px;

  padding: 48px;

  display: flex;
  flex-direction: column;

  border: 1px solid $neutrals6;
  border-radius: 16px;
  box-shadow: 0 64px 64px rgba(31, 47, 70, 0.12);
  @include d {
    padding: 24px;
  }

  @include t {
    position: static;

    margin: auto;

    width: 352px;
  }

  @include m {
    width: 100%;
  }

  @include dark {
    background: $dark;
    box-shadow: inset 0 0 0 2px $neutrals3;
    border: none;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 24px;

  display: none;

  width: 40px;
  height: 40px;

  font-size: 0;

  background: $light;
  background: $dark;
  border: 2px solid $neutrals6;

  // @include dark {
  border-color: $neutrals3;
  border-radius: 50%;

  svg {
    transition: transform 0.2s;
    fill: $neutrals2;
  }

  svg {
    fill: $light;
  }

  @include t {
    display: inline-block;
  }
  // }
}

.info {
  margin-bottom: 24px;

  @include body-bold-1;
  @include dark {
    color: $light;
  }
}

.preview {
  position: relative;

  overflow: hidden;

  border-radius: 16px;

  & > img {
    width: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.link {
  padding: 20px 0;

  color: $neutrals2;

  @include dark {
    color: $light;
  }
}

.line {
  display: flex;
  align-items: flex-start;

  &:first-child {
    margin-bottom: 12px;
  }
}

.title {
  margin-right: auto;
  padding-top: 1px;

  overflow: hidden;

  max-width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: $neutrals2;

  @include body-bold-2;
  @include dark {
    color: $neutrals6;
  }
}

.price {
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;

  border-radius: 4px;
  flex-shrink: 0;
}

.innerPrice {
  padding: 0 6px;

  border: 2px solid $orange-kephi;
  border-radius: 3px;
}

.innerText {
  color: $orange-kephi;
}

.counter {
  @include body-14;
  color: $neutrals3;

  svg {
    fill: transparent;
    stroke: $orange-kephi;
  }
  @include dark {
    color: $neutrals6;
  }
}

.users {
  margin-right: auto;

  display: flex;
}

.avatar {
  display: flex;

  width: 24px;
  height: 24px;

  border: 2px solid $light;
  border-radius: 50%;
  flex-shrink: 0;

  @include dark {
    border-color: $dark;
  }
  img {
    width: 21px;
    height: 21px;

    border-radius: 50%;
    object-fit: cover;
  }

  &:not(:first-child) {
    margin-left: -8px;
  }
}

.foot {
  margin-top: 12px;
  padding-top: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $neutrals4;

  @include caption-2;
  border-color: $neutrals3;
  border-top: 1px solid $neutrals6;
  @include dark {
    border-color: $neutrals3;
    border-top: 1px solid $neutrals3;
  }
}

.status {
  svg {
    margin: (-2px) 4px 0 0;
    fill: $neutrals4;
  }

  span {
    font-weight: 600;

    color: $neutrals3;

    @include dark {
      color: $light;
    }
  }
}

.clear {
  margin-top: 24px;

  display: inline-flex;
  align-items: center;

  color: $neutrals4;

  transition: color 0.2s;

  @include button-1;

  svg {
    margin-right: 8px;

    transition: fill 0.2s;
    fill: $neutrals4;
    @include dark {
      fill: $neutrals5;
    }
  }

  &:hover {
    color: $orange-kephi;
    @include dark {
      color: $orange-kephi;
    }

    svg {
      fill: $orange-kephi;
    }
  }

  @include dark {
    color: $neutrals5;
  }
}
